import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    Filter, 
    ReferenceInput, 
    AutocompleteInput,
    ReferenceField,
    TextField,
} from 'react-admin';

import PersonaField from '../personas/PersonaField';
import { fullName } from '../personas/utils';
import { DATE_OPTIONS } from '../../libs/sgx/shared/date';

const AsignacionesFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="personaId" reference="personas" alwaysOn>
            <AutocompleteInput optionText={fullName} />
        </ReferenceInput>
    </Filter>
);

const AsignacionescontratadasList = props => (
    <List {...props} filters={<AsignacionesFilter />} >
        <Datagrid rowClick="show">
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <TextField source="horasMensuales" />
            <TextField source="detalle" />
            <DateField source="fechaInicio" locales="es-AR" options={DATE_OPTIONS}/> 
            <DateField source="fechaFin" locales="es-AR" options={DATE_OPTIONS}/> 
        </Datagrid>
    </List>
);

export default AsignacionescontratadasList;