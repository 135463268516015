import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { FINANCE, PARTNER } from '../roles';

import FormatPaintIcon from '@material-ui/icons/FormatPaint';

import AsignacionContratadaPivotList from './AsignacionContratadaPivotList';

export const asignacionContratadaPivotPermissions = [
    FINANCE,
    PARTNER,
];

const asignacionContratadaPivot = (permisos: SGXPermissions) => ({
    icon: FormatPaintIcon,
    list: permisos.hasAnyAssignment(...asignacionContratadaPivotPermissions) ? AsignacionContratadaPivotList : undefined,
    options: {
        submenu: 'pivot'
    }
});

export default asignacionContratadaPivot;