import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import {
    LIST,
    EDIT,
} from './permissions';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import DolarList from './DolarList';
import DolarCreate from './DolarCreate';

const dolar = (permisos: SGXPermissions) => ({
    create: permisos.hasAnyAssignment(...EDIT) ? DolarCreate : undefined,
    icon: MonetizationOnIcon,
    list: permisos.hasAnyAssignment(...LIST) ? DolarList : undefined,
});

export default dolar;
