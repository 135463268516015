
import ContratacionesCard from './ContratacionesCard';
import ResumenProyectosCard from './ResumenProyectosCard';
import GastosPersonaCard from './GastosPersonaCard';
import RatesPorJob from './charts/RatesPorJob';

const YEAR_RANGE = ["2024","2023", "2022"];

export {
    ContratacionesCard,
    GastosPersonaCard,
    ResumenProyectosCard,
    RatesPorJob,
    YEAR_RANGE,
};
