import { makeStyles } from '@material-ui/core/styles';

const useFormStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    preferred: { textDecorationStyle: 'dotted', textDecoration: 'underline' },
    unpreferred: { textDecoration: 'underline' },
});

export {
    useFormStyles,
};