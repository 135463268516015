import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { FINANCE, PARTNER } from '../roles';

import FormatPaintIcon from '@material-ui/icons/FormatPaint';

import RetribucionProyectoPivotList from './RetribucionProyectoPivotList';

export const retribucionProyectoPivotPermissions = [
    FINANCE,
    PARTNER,
];

const retribucionProyectoPivot = (permisos: SGXPermissions) => ({
    icon: FormatPaintIcon,
    list: permisos.hasAnyAssignment(...retribucionProyectoPivotPermissions) ? RetribucionProyectoPivotList : undefined,
    options: {
        submenu: 'pivot'
    }
});

export default retribucionProyectoPivot;