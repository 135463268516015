import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

import { DIVISA_MAP } from './utils';

const DivisaField = (props) => {
    const { source = 'divisa' } = props;
    const record = useRecordContext(props);
    return <span>{DIVISA_MAP[record[source]]}</span>;
}

DivisaField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string,
};

export default DivisaField;
