enum Divisa {
    DOLAR = 'DOLAR',
    PESO = 'PESO'
};

const divisaConfig = {
    [Divisa.DOLAR]: {prefix: '🇺🇸', options: {style: 'currency', currency: 'USD'}, },
    [Divisa.PESO]: {prefix: '🇦🇷', options: {style: 'currency', currency: 'ARS'}, },
};

const UNKNOWN_CONFIG = {prefix: '🏴‍☠️', options: {style: 'currency', currency: 'EUR'}, };

const genericCurrencyFormat = new Intl.NumberFormat('es-AR', { 
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const currencyFormatter = (divisa: Divisa) => {
    const { options, prefix } = divisaConfig[divisa] || UNKNOWN_CONFIG;

    const numberFormat = new Intl.NumberFormat('es-AR', options);
    return (monto: number) => monto !== undefined ? `${numberFormat.format(monto)} ${prefix}` : '';
};

const genericCurrency = (monto: number) => `$ ${genericCurrencyFormat.format(monto)}`;

const divisaOptions = (divisa: Divisa) => (divisaConfig[divisa] || UNKNOWN_CONFIG).options;

export { 
    Divisa, 
    currencyFormatter, 
    genericCurrency,
    divisaOptions,
};