import * as React from 'react';
import { useState, useEffect } from 'react';
import { 
    useDataProvider, 
    Loading, 
    Error, 
    useRecordContext,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';

import TypographyField from '../../libs/sgx/components/TypographyField';

import { currencyFormatter, Divisa } from '../../libs/sgx/shared/currency';
import { formatDistance, dayFormatter } from '../../libs/sgx/shared/date';

const divisaMap = {
    DOLAR: Divisa.DOLAR,
    PESO: Divisa.PESO,
};

const CuentaResumen = (props) => {

    const dataProvider = useDataProvider();
    const [resumen, setResumen] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const cuenta = useRecordContext(props);


    useEffect(() => {
        const params = { id: cuenta.id };
        dataProvider.getOne('resumenes', params)
            .then((result) => {
                if (result) {
                    setResumen(result?.data);
                    setLoading(false);
                }
            })
            .catch((error = {}) => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider, cuenta.id]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!resumen) return null;

    return (
        <Grid container spacing={1}>
            <Grid item sm={6}>
                <TypographyField record={cuenta} source="nombre" variant="h5"></TypographyField>
            </Grid>
            <Grid item sm={6} >
                <TypographyField record={resumen} source="saldo" variant="h4" align="right" formatter={currencyFormatter(divisaMap[cuenta.divisa])}></TypographyField>
            </Grid>
            <Grid item sm={6} >
                <TypographyField record={cuenta} source="fin" variant="subtitle1" formatter={(date => `Movimientos del balance ${dayFormatter(date)}`)}></TypographyField>
            </Grid>
            <Grid item sm={6}>
                <TypographyField record={resumen} source="hasta" variant="subtitle1" align="right" formatter={date => `Último movimiento ${formatDistance(date)}`}></TypographyField>
            </Grid>
        </Grid>
    )
};

export default CuentaResumen;
