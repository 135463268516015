import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { FINANCE, PARTNER } from '../roles';

import FormatPaintIcon from '@material-ui/icons/FormatPaint';

import PagoPivotList from './PagoPivotList';
import RetribucionPivotShow from '../retribucion-pivot/RetribucionPivotShow';

export const pagoPivotPermissions = [
    FINANCE,
    PARTNER,
];

const pagoPivot = (permisos: SGXPermissions) => ({
    icon: FormatPaintIcon,
    list: permisos.hasAnyAssignment(...pagoPivotPermissions) ? PagoPivotList : undefined,
    show: RetribucionPivotShow,
    options: {
        submenu: 'pivot'
    }
});

export default pagoPivot;