import React from 'react';
import {
    DateInput,
    Edit,
    NumberInput,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    FormDataConsumer,
    SelectInput,
} from 'react-admin';

import { useFormStyles } from '../../libs/sgx/components/layout';

import { cuentaSimple } from '../cuentas-personales/utils';
import PersonaInput from './PersonaAutocompleteInput';



const PersonaPagoEjecutadoRowEdit = props => {
    const notify = useNotify();
    const classes = useFormStyles();
    const onSuccess = () => {
        notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }});
    };
    return <Edit {...props} title=" " onSuccess={onSuccess} mutationMode="pessimistic">
        <SimpleForm>
            <DateInput source="fechaEsperada" validate={[required()]} formClassName={classes.inlineBlock}/>
            <NumberInput source="monto" locales="es-AR" validate={[required()]} formClassName={classes.inlineBlock}/>
            <NumberInput source="neto" locales="es-AR" formClassName={classes.inlineBlock} helperText="resources.pagos-ejecutados.fieldsHelper.neto" />
            <FormDataConsumer >
                {({ formData: {divisa, personaId = -1}, ...rest }) => (
                    <ReferenceInput source="cuentaPersonalId" reference="cuentas-personales" 
                        filter={{ divisa, personaId }}
                        {...rest}
                        >
                        <SelectInput optionText={cuentaSimple} resettable/>
                    </ReferenceInput>
                )}
            </FormDataConsumer>

            <ReferenceInput source="personaEncargadaId" reference="personas" allowEmpty >
                <PersonaInput />
            </ReferenceInput>

            <TextInput source="detalle" multiline fullWidth />
        </SimpleForm>
    </Edit>
};

export default PersonaPagoEjecutadoRowEdit;