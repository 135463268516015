import React from 'react';
import {
    ReferenceInput,
    AutocompleteInput,
    DateInput,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    required,
} from 'react-admin';

import { fullName } from '../cuentas/utils';

const MovimientosCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="cuentaId" reference="cuentas" sort={{ field: 'fin', order: 'DESC' }} validate={[required()]}>
                <AutocompleteInput optionText={fullName} />
            </ReferenceInput>
            <DateInput source="fecha" validate={[required()]}/>
            <TextInput source="detalle" />
            <NumberInput source="monto" locales="es-AR" validate={[required()]}/>
            <TextInput source="comentarios" multiline />
        </SimpleForm>
    </Create>
);

export default MovimientosCreate;
