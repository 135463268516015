import React from 'react';
import {
    List,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    Datagrid,
    TextField,
    ReferenceField,
    TextInput,
} from 'react-admin';

import PersonaField from '../personas/PersonaField';
import { fullName } from '../personas/utils';

const fechaFin = new Date().toISOString().substring(0,10);

const AsignacionPivotFilter = (props) => (
    <Filter {...props}>
        <TextInput source="anio" alwaysOn />
        <TextInput source="mes" alwaysOn />
        <ReferenceInput source="jobId" reference="jobs" >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="proyectoId" reference="proyectos" >
            <AutocompleteInput optionText="nombre" />
        </ReferenceInput>
        <ReferenceInput source="personaId" reference="personas" >
            <AutocompleteInput optionText={fullName} />
        </ReferenceInput>
    </Filter>
);

const AsignacionPivotList = props => (
    <List {...props}
        filters={<AsignacionPivotFilter />}
        filterDefaultValues={{ fechaFin }} 
        bulkActionButtons={false}
    >
        <Datagrid rowClick={null} >
            <TextField source="anio" />
            <TextField source="mes" />
            <ReferenceField source="proyectoId" reference="proyectos" link="show">
                <TextField source="nombre" />
            </ReferenceField>
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <ReferenceField source="jobId" reference="jobs" link={false}>
                <TextField source="name" />
            </ReferenceField>

            <TextField source="horasMensuales" />
        </Datagrid>
    </List>
);

export default AsignacionPivotList;