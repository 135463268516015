// in src/Foo.js
import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

import ResumenProyectosCard from '../ResumenProyectosCard';

const ProyectosPage = () => (
    <Card>
        <Title title="Balance de proyectos" />
        <CardContent>
            <ResumenProyectosCard />
        </CardContent>
    </Card>
);

export default ProyectosPage;