import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import {
    LIST,
} from './permissions';

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import MovimientosCreate from './MovimientosCreate';
import MovimientosList from './MovimientosList';
import MovimientosShow from './MovimientosShow';
import MovimientosEdit from './MovimientosEdit';


const movimientos = (permisos: SGXPermissions) => ({
    icon: AccountBalanceWalletIcon,
    create: MovimientosCreate,
    edit: MovimientosEdit,
    list: permisos.hasAnyAssignment(...LIST) ? MovimientosList : undefined,
    show: MovimientosShow,
    options: {
        submenu: 'banking'
    }
});

export default movimientos;
