import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import {
    LIST,
    EDIT,
} from './permissions';

import BusinessIcon from '@material-ui/icons/Business';

import BusinessUnitCreate from './BusinessUnitCreate';
import BusinessUnitList from './BusinessUnitList';
import BusinessUnitShow from './BusinessUnitShow';
import BusinessUnitEdit from './BusinessUnitEdit';

const businessUnits = (permisos: SGXPermissions) => ({
    icon: BusinessIcon,
    create: permisos.hasAnyAssignment(...EDIT) ? BusinessUnitCreate : undefined,
    edit: permisos.hasAnyAssignment(...EDIT) ? BusinessUnitEdit : undefined,
    list: permisos.hasAnyAssignment(...LIST) ? BusinessUnitList : undefined,
    show: BusinessUnitShow,
    options: {
        submenu: 'business'
    }
});

export default businessUnits;
