import React from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { monthFormatter } from '../../../libs/sgx/shared/date';

import { cubejsApiTokenPromise, cubejsOptions } from './cube-config';


const cubejsApi = cubejs(cubejsApiTokenPromise, cubejsOptions);

const COLORS_SERIES = ['#FF6492', '#141446', '#7A77FF'];
const LABELS_SERIES = {
    'MovimientosPorPersona.deuda_pesos': 'Adeudado',
    'MovimientosPorPersona.a_cobrar_pesos': 'Sueldo', 
    'MovimientosPorPersona.pagado_pesos': 'Pagado', 
  };
const commonOptions = {
  maintainAspectRatio: false,
};

ChartJS.register(...registerables);

const renderChart = ({ resultSet, error }) => {
    if (error) {
        return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
        return <LinearProgress />;
    }

    const data = {
        labels: resultSet.categories().map((c) => monthFormatter(c.x)),
        datasets: resultSet.series().map((s, index) => ({
          label: LABELS_SERIES[s.key],
          data: s.series.map((r) => r.value),
          borderColor: COLORS_SERIES[index],
          backgroundColor: COLORS_SERIES[index],
          fill: false,
        })),
      };
      const options = { ...commonOptions };

    return (
        <Line data={data} options={options} />
    );

};

const ChartRenderer = ({ personaId, year }) => {
    return (
        <QueryRenderer
            query={{
                "measures": [
                    "MovimientosPorPersona.deuda_pesos",
                    "MovimientosPorPersona.a_cobrar_pesos",
                    "MovimientosPorPersona.pagado_pesos",
                ],
                "timeDimensions": [
                    {
                        "dimension": "MovimientosPorPersona.fecha",
                        "granularity": "month",
                        "dateRange": [`${year}-01-01`,`${year}-12-31`],
                      }
                ],
                "order": {},
                "filters": [
                    {
                        "member": "MovimientosPorPersona.persona_id",
                        "operator": "equals",
                        "values": [
                            `${personaId}`
                        ]
                    }
                ]
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) => renderChart({
                ...props,
                chartType: 'table',
                pivotConfig: {
                    "x": [
                        "MovimientosPorPersona.fecha.month"
                    ],
                    "y": [
                        "measures"
                    ],
                    "fillMissingDates": true,
                    "joinDateRange": false
                }
            })}
        />
    );
};

export default ChartRenderer;
