import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { ADMINISTRATIVE, FINANCE, HR, PARTNER } from '../roles';

import FormatPaintIcon from '@material-ui/icons/FormatPaint';

import { ProyectoCreate } from './ProyectoCreate';
import ProyectoEdit from './ProyectoEdit';
import ProyectoList from './ProyectoList';
import ProyectoShow from './ProyectoShow';

export const proyectosViewPermissions = [
    ADMINISTRATIVE,
    FINANCE,
    HR,
    PARTNER,
];

export const proyectosUpdatePermissions = [
    ADMINISTRATIVE,
    FINANCE,
    PARTNER,
];

const proyectos = (permisos: SGXPermissions) => ({
    icon: FormatPaintIcon,
    create: permisos.hasAnyAssignment(...proyectosUpdatePermissions) ? ProyectoCreate : undefined,
    edit: permisos.hasAnyAssignment(...proyectosUpdatePermissions) ? ProyectoEdit : undefined,
    list: permisos.hasAnyAssignment(...proyectosViewPermissions) ? ProyectoList : undefined,
    show: ProyectoShow,
    options: {
        submenu: 'work'
    }
});

export default proyectos;