import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { FINANCE, PARTNER } from '../roles';

import FormatPaintIcon from '@material-ui/icons/FormatPaint';

import RetribucionPivotList from './RetribucionPivotList';
import RetribucionPivotShow from './RetribucionPivotShow';

export const retribucionPivotPermissions = [
    FINANCE,
    PARTNER,
];

const retribucionPivot = (permisos: SGXPermissions) => ({
    icon: FormatPaintIcon,
    list: permisos.hasAnyAssignment(...retribucionPivotPermissions) ? RetribucionPivotList : undefined,
    show: RetribucionPivotShow,
    options: {
        submenu: 'pivot'
    }
});

export default retribucionPivot;