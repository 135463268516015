import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext, useTranslate } from 'react-admin';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const TextBlockField = (props) => {
    const { source, resource, formatter = (value) => value } = props;

    const translate = useTranslate();
    const record = useRecordContext(props);
    return <>
        <FormControl>
            <TextField label={translate(`resources.${resource}.fields.${source}`)} 
                multiline defaultValue={formatter(record[source])} InputProps={{
                    readOnly: true,
                }}/>
        </FormControl>

    </>;
}

TextBlockField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    variant: PropTypes.string,
    formatter: PropTypes.func,
};

export default TextBlockField;
