import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    TopToolbar, 
    CreateButton, 
    ExportButton,
} from 'react-admin';

import ImportButton from '../../libs/sgx/components/ImportButton';

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
        <ExportButton/>
        <ImportButton resource="dolar" />
    </TopToolbar>
);

const DolarList = props => (
    <List {...props}
        actions={<ListActions/>}
        sort={{ field: 'id', order: 'DESC' }}
    >
        <Datagrid rowClick={null}>
            <DateField source="id" locales="es-AR" options={{ timeZone: 'UTC' }} />
            <NumberField source="oficial" locales="es-AR" options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}/>
            <NumberField source="alternativo" locales="es-AR" options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}/>
        </Datagrid>
    </List>
);

export default DolarList;