import React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles({
  root: {
    width: '10vw',
    minWidth: 275,
  },
  rowRoot: {
    width: '6vw',
    minWidth: 140,
  },
});

const defaultCalcValue = (d, key) => {
  const value = d && d[key];
  return value;
};

const chip = ({value, icon, color}) => <Chip
  avatar={<Avatar>{icon}</Avatar>}
  color={color}
  label={value}
/>

const box = ({value, label, classes, translate}) => (
  <div className={classes.root}>
    <Typography variant="h5" component="h5" color="inherit">
      {value}
    </Typography>
    <Typography variant="caption" component="h6" color="secondary">
      {translate(label)}
    </Typography>
  </div>
);

const rowBox = ({value, classes}) => (
  <div className={classes.rowRoot}>
    <Typography variant="h6" component="h6" color="inherit">
      {value}
    </Typography>
  </div>
);

const RowChartFieldData = ({
  data,
  source,
  cube,
  count,
  label,
  format = (v) => v,
  calculate = defaultCalcValue,
  hideIfEmpty,
  icon,
  color = 'default'
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const sourceCube = `${cube}.${source}`;
  const calcValue = calculate;
  const formatedValue = format(calcValue(data, sourceCube));

  const labelToTranslate = label || `cubes.${sourceCube}`;

  if (!formatedValue && hideIfEmpty) {
    return  <span></span >;
  }

  if (icon) {
    return chip({value: formatedValue, icon, color});
  }

  if (count > 1) {
    return  rowBox({
      value: formatedValue,
      classes,
    });
  }

  return box({
    value: formatedValue, 
    label: labelToTranslate,
    classes,
    translate,
  });
}


export default RowChartFieldData;
