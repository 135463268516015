import React from 'react';
import {
    DateField,
    ReferenceField,
    Show,
    TabbedShowLayout,
    TextField,
    ReferenceManyField,
    Datagrid,
    Tab,
    EditButton,
    NumberField,
    CloneButton,
    Pagination,
    TabbedShowLayoutTabs,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { DATE_OPTIONS, TODAY, isDatePast } from '../../libs/sgx/shared/date';
import ShowFormTitle from '../../libs/sgx/components/ShowFormTitle';

import CreateRelatedButton from '../components/CreateRelatedButton';
import { fullName } from './utils';
import {
    LIST as RETRIBUCION_LIST,
} from '../retribuciones/permissions';
import { pagosEjecutadosPermissions } from '../pagos-ejecutados/index';
import PersonaMovimientos from './PersonaMovimientos';
import PersonaPagoEjecutadoRowEdit from './PersonaPagoEjecutadoRowEdit';
import PartialCloneButton from '../../libs/sgx/components/PartialCloneButton';
import CuentaPersonalField from '../cuentas-personales/CuentaPersonalField';
import RowChartField from '../../libs/sgx/components/RowChartField';
import RowChartFieldData from '../../libs/sgx/components/RowChartFieldData';
import { genericCurrency } from '../../libs/sgx/shared/currency';
import PagoEjecutadoMontoField from '../pagos-ejecutados/PagoEjecutadoMontoField';

const useTabsStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100vw',
    },
}));

const pagoPendienteRowStyle = (record) => ({
    backgroundColor: record.estado === 'NUEVO' && isDatePast(record.fechaEsperada) ? '#efe' : 'white',
});

const PAGOS_RESET_VALUES = { estado: 'NUEVO', fechaEjecucion: undefined };

const PersonaShow = (props) => {
    const accesoARetribuciones = props.permissions?.hasAnyAssignment(...RETRIBUCION_LIST);
    const accesoAPagoEjecutados = props.permissions?.hasAnyAssignment(...pagosEjecutadosPermissions);

    const classes = useTabsStyles();

    return (
  <Show {...props} title={<ShowFormTitle mapper={fullName}/>}>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="on" className={classes.root} />}>
        <Tab label="resources.personas.tabs.general">
            <TextField source="cuil" />
            <TextField source="nombre" />
            <TextField source="apellido" />
            <ReferenceField source="generoId" reference="generos">
                <TextField source="nombre" />
            </ReferenceField>
            <DateField source="fechaNacimiento" locales="es-AR" options={DATE_OPTIONS}/>
            <TextField source="telefono" />
            <TextField source="email" />
        </Tab>
        <Tab label="resources.personas.tabs.asignacionescontratadas" path="asignacionescontratadas">
            <CreateRelatedButton
                reference="asignacionescontratadas"
                refFieldName="personaId"
                label="resources.personas.buttons.asignacionescontratadas"
            />
            <ReferenceManyField
                addLabel={false}
                reference="asignacionescontratadas"
                target="personaId"
                sort={{ field: 'fechaInicio', order: 'DESC' }}
                pagination={<Pagination />}
            >
                <Datagrid rowClick="show">
                    <TextField source="detalle" />
                    <TextField source="horasMensuales" />
                    <DateField source="fechaInicio" locales="es-AR" options={DATE_OPTIONS}/>
                    <DateField source="fechaFin" locales="es-AR" options={DATE_OPTIONS}/>
                    <EditButton />
                </Datagrid>
            </ReferenceManyField>
        </Tab>
        {accesoARetribuciones &&
            <Tab label="resources.personas.tabs.retribuciones" path="retribuciones">
                <RowChartField cube="MovimientosPorPersona" 
                    filters={[
                        {
                            "member": "MovimientosPorPersona.persona_id",
                            "operator": "equals",
                            "values": [
                                `${props.id}`
                            ]
                        },
                        {
                            "member": "MovimientosPorPersona.anio",
                            "operator": "equals",
                            "values": [
                                `${TODAY.year}`
                            ]
                        },
                        {
                            "member": "MovimientosPorPersona.mes",
                            "operator": "equals",
                            "values": [
                                `${TODAY.month}`
                            ]
                        },
                    ]}
                    measures={[
                        "a_cobrar_pesos",
                        "a_cobrar_dolares",
                        "deuda_pesos",
                        "deuda_dolares",
                    ]}
                    dimensions={[
                        "anio",
                        "mes",
                    ]}
                    order={{
                        "MovimientosPorPersona.anio": "desc",
                        "MovimientosPorPersona.mes": "desc"
                    }}
                >
                    <RowChartFieldData icon="🇦🇷" source="a_cobrar_pesos" calculate={(row) => row && parseInt(row['PESO,MovimientosPorPersona.a_cobrar_pesos'] || 0, 10)} format={genericCurrency} />
                    <RowChartFieldData icon="🇺🇸" source="a_cobrar_dolares" />
                    <RowChartFieldData icon="🇦🇷" source="deuda_pesos" hideIfEmpty color="secondary"/>
                    <RowChartFieldData icon="🇺🇸" source="deuda_dolares" hideIfEmpty color="secondary"/>
                </RowChartField>

                <CreateRelatedButton
                    reference="retribuciones"
                    refFieldName="personaId"
                    label="resources.personas.buttons.retribuciones"
                />
                <ReferenceManyField
                    addLabel={false}
                    reference="retribuciones"
                    target="personaId"
                    sort={{ field: 'fechaInicio', order: 'DESC' }}
                    pagination={<Pagination />}
                >
                    <Datagrid rowClick="show">
                        <TextField source="detalle" />
                        <DateField source="fechaInicio" locales="es-AR" options={DATE_OPTIONS}/> 
                        <DateField source="fechaFin" locales="es-AR" options={DATE_OPTIONS}/> 
                        <NumberField source="montoPesos" locales="es-AR" options={{style: 'currency', currency: 'ARS'}}/>
                        <NumberField source="montoDolares" locales="es-AR" options={{style: 'currency', currency: 'USD'}}/>
                        <EditButton />
                        <CloneButton />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        }
        {accesoAPagoEjecutados &&
            <Tab label="resources.personas.tabs.pagos-ejecutados" path="pagos-ejecutados">
                <CreateRelatedButton
                    reference="pagos-ejecutados"
                    refFieldName="personaId"
                    label="resources.personas.buttons.pagos-ejecutados"
                />
                <ReferenceManyField
                    addLabel={false}
                    reference="pagos-ejecutados"
                    target="personaId"
                    sort={{ field: 'fechaEsperada', order: 'DESC' }}
                    pagination={<Pagination />}
                >
                    <Datagrid rowClick="expand" expand={<PersonaPagoEjecutadoRowEdit />} rowStyle={pagoPendienteRowStyle} >
                        <DateField source="fechaEsperada" locales="es-AR" options={DATE_OPTIONS}/>
                        <TextField source="estado" />
                        <DateField source="fechaEjecucion" locales="es-AR" options={DATE_OPTIONS}/>
                        <ReferenceField source="cuentaPersonalId" reference="cuentas-personales" link={false}>
                            <CuentaPersonalField />
                        </ReferenceField>
                        <PagoEjecutadoMontoField source="monto"  preferSource="neto" />
                        
                        <TextField source="detalle" />
                        <EditButton />
                        <PartialCloneButton mapResource={PAGOS_RESET_VALUES}/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        }
        <Tab label="resources.personas.tabs.asignaciones" path="asignaciones">
            <CreateRelatedButton
                reference="asignaciones"
                refFieldName="personaId"
                label="resources.personas.buttons.asignaciones"
            />
            <ReferenceManyField
                addLabel={false}
                reference="asignaciones"
                target="personaId"
                sort={{ field: 'fechaInicio', order: 'DESC' }}
                pagination={<Pagination />}
            >
                <Datagrid rowClick="show">
                        <ReferenceField source="proyectoId" reference="proyectos" link="show">
                            <TextField source="nombre" />
                        </ReferenceField>
                        <ReferenceField source="jobId" reference="jobs" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="detalle" />
                        <TextField source="horasMensuales" />
                        <DateField source="fechaInicio" locales="es-AR" options={DATE_OPTIONS}/>
                        <DateField source="fechaFin" locales="es-AR" options={DATE_OPTIONS}/>
                        <EditButton />
                </Datagrid>

            </ReferenceManyField>
        </Tab>
        {accesoARetribuciones && accesoAPagoEjecutados && <Tab label="resources.proyectos.tabs.grafico" path="grafico">
            <PersonaMovimientos source="id"/>
        </Tab>}
    </TabbedShowLayout>
  </Show>
    )
};

export default PersonaShow;