import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { PARTNER, FINANCE, ADMINISTRATIVE, HR } from '../roles';

import PersonIcon from '@material-ui/icons/Person';

import { PersonaCreate } from './PersonaCreate';
import { PersonaList } from './PersonaList';
import PersonaShow from './PersonaShow';
import { PersonaEdit } from './PersonaEdit';


const personas = (permisos: SGXPermissions) => ({
    icon: PersonIcon,
    list: permisos.hasAnyAssignment(PARTNER, FINANCE, ADMINISTRATIVE, HR,) ? PersonaList : undefined,
    show: PersonaShow,
    create: PersonaCreate,
    edit: PersonaEdit,
    options: {
        submenu: 'staff'
    }
});

export default personas;