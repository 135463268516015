import React from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { monthCapitalFormatter } from '../../../libs/sgx/shared/date';
import { cubejsApiTokenPromise, cubejsOptions } from './cube-config';

const COLORS_SERIES = ['#FF6492', '#141446', '#7A77FF'];

const commonOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
  }
};

const cubejsApi = cubejs(cubejsApiTokenPromise, cubejsOptions);

ChartJS.register(...registerables);

const renderChart = ({ resultSet, error, pivotConfig }) => {
  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return <LinearProgress />;
  }

  const data = {
    labels: resultSet.categories().map((c) => monthCapitalFormatter(c.x)),
    datasets: resultSet.series().map((s, index) => ({
      label: s.key,
      data: s.series.map((r) => r.value),
      backgroundColor: COLORS_SERIES[index],
      borderColor: COLORS_SERIES[index],
    })),
  };
  console.log('data', data);
  const options = {
    ...commonOptions,
  };
  return <Line data={data} options={options} />;

};

const ChartRenderer = ({
  measures,
  filters,
  timeDimensions,
}) => {

  return (
    <QueryRenderer
      query={{
        measures,
        timeDimensions,
        filters,
        "order": {}
      }}
      cubejsApi={cubejsApi}
      resetResultSetOnChange={false}
      render={(props) => renderChart({
        ...props,
        chartType: 'line',
        pivotConfig: {
          "x": [
            "Contrataciones.fecha.month"
          ],
          "y": [
            "measures"
          ],
          "fillMissingDates": true,
          "joinDateRange": false
        }
      })}
    />
  );
};

export default ChartRenderer;
