import * as React from 'react';
import { 
    useRecordContext,
    NumberField,
} from 'react-admin';
import PropTypes from 'prop-types';

import { DIVISA_CURRENCY } from './utils';

const MontoField = ({ divisa, ...props }) => {
    
    const record = useRecordContext(props);
    const currency = divisa || DIVISA_CURRENCY[record['divisa']];

    return <NumberField {...props} locales="es-AR" options={{style: 'currency', currency}}/>;
}

MontoField.defaultProps = {
   addLabel: true,
   textAlign: 'right',
};

MontoField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string,
};

export default MontoField;
