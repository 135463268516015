
import { es } from 'date-fns/locale';
import {
    parseISO,
    formatDistanceToNow,
    isPast,
    formatISO,
} from 'date-fns';

enum Month {
    January = 0,
    June = 5,
    July = 6,
};

const DATE_OPTIONS = { timeZone: 'UTC', day: '2-digit', month: '2-digit', year: '2-digit' };

const locales = 'es-AR';
const options = { timeZone: 'UTC' };

const dateFormat = new Intl.DateTimeFormat(locales, {
    ...options,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
});
const dayFormat = new Intl.DateTimeFormat(locales, {
    ...options,
    day: 'numeric',
    month: 'long',
    year: 'numeric',
});

const monthFormat = new Intl.DateTimeFormat(locales, {
    ...options,
    month: 'long',
    year: '2-digit',
});

const monthCapitalFormat = new Intl.DateTimeFormat(locales, {
    ...options,
    month: 'short',
});

const yearShortFormat = new Intl.DateTimeFormat(locales, {
    ...options,
    year: '2-digit',
});

const dateFormatterParams = {locales, options: DATE_OPTIONS};

const dateFormatter = (dateStr: string) => dateStr ? dateFormat.format(new Date(dateStr)) : undefined;

const dayFormatter = (dateStr: string): string => dateStr ? dayFormat.format(new Date(dateStr)) : '';

const monthFormatter = (dateStr: string): string => dateStr ? monthFormat.format(new Date(dateStr)) : '';

/**
 * @param dateStr: 2023-06-30
 * @returns "J"
 */
const monthCapitalFormatter = (dateStr: string): string => dateStr ? monthCapitalFormat.format(new Date(dateStr)) : '';

/**
 * @param dateStr: 2023-06-30
 * @returns "23"
 */
const yearShortFormatter = (dateStr: string): string => dateStr ? yearShortFormat.format(new Date(dateStr)) : '';

const defaultPeriodo = (month: Month): {start: Date, end: Date} => {
    const currentDate = new Date();
    const year = currentDate.getFullYear() - (currentDate.getMonth() < month ? 1 : 0);
    const start = new Date(year, month, 1);
    const end = new Date(year + 1, month, 0);
    return {start, end}
};

const formatDistance = (sourceValue: string) => sourceValue && formatDistanceToNow(
    parseISO(sourceValue),
    { locale: es, addSuffix: true }
);

const isDatePast = (sourceValue: string): boolean => isPast(parseISO(sourceValue));

const TODAY: {year: number, month: Month} = {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
};

const dateISOFormatter = (dateStr: string): string => !dateStr ? '' : formatISO(parseISO(dateStr), { representation: 'date' });

export { 
    dateFormatter, 
    dateFormatterParams, 
    dateISOFormatter,
    dayFormatter,
    defaultPeriodo, 
    formatDistance,
    isDatePast,
    monthCapitalFormatter,
    monthFormatter,
    yearShortFormatter,
    Month, 
    DATE_OPTIONS,
    TODAY,
};

