import React from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { monthFormatter } from '../../../libs/sgx/shared/date';
import { cubejsApiTokenPromise, cubejsOptions } from './cube-config';

const COLORS_SERIES = {
  'MovimientosProyectos.monto_pesos_acc': '#33b9ba',
  'MovimientosProyectos.monto_dolares_acc': '#137a49',
  'MovimientosProyectos.monto_pesos': '#a3b2a1',
  'MovimientosProyectos.monto_dolares': '#a3b2a1', 
};
const LABELS = {
  'MovimientosProyectos.monto_pesos_acc': 'Acumulado en pesos',
  'MovimientosProyectos.monto_dolares_acc': 'Acumulado en dolares', 
  'MovimientosProyectos.monto_pesos': 'Monto en pesos',
  'MovimientosProyectos.monto_dolares': 'Monto en dolares', 
}
const commonOptions = {
  maintainAspectRatio: false,
};

const cubejsApi = cubejs(cubejsApiTokenPromise, cubejsOptions);

ChartJS.register(...registerables);

const renderChart = ({ resultSet, error, pivotConfig }) => {
  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return <LinearProgress />;
  }

  const data = {
    labels: resultSet.categories().map((c) => monthFormatter(c.x)),
    datasets: resultSet.series().map((s) => {
      const label = LABELS[s.key];
      const borderColor = COLORS_SERIES[s.key];
      // console.log('data map', s.key);
      return {
        label,
        data: s.series.map((r) => r.value),
        borderColor,
        fill: true,
      }
    }),
  };
  const options = { ...commonOptions };
  return <Line data={data} options={options} />;

};

const GastosProyecto = ({
  proyectoId, 
  moneda, 
  dateRange = [
    "2022-01-01",
    "2024-12-31"
  ]
}) => {
  const filters = proyectoId ? [
    {
      "member": "MovimientosProyectos.proyecto_id",
      "operator": "equals",
      "values": [
        `${proyectoId}`
      ]
    }
  ] : undefined;
  return (
    <QueryRenderer
      query={{
        "measures": [
          `MovimientosProyectos.monto_${moneda}_acc`,
          `MovimientosProyectos.monto_${moneda}`,
        ],
        "timeDimensions": [
          {
            "dimension": "MovimientosProyectos.fecha",
            "granularity": "month",
            dateRange,
          }
        ],
        "dimensions": [
          
        ],
        filters
      }}
      cubejsApi={cubejsApi}
      resetResultSetOnChange={false}
      render={(props) => renderChart({
        ...props,
        chartType: 'line',
        pivotConfig: {
          "x": [
            "MovimientosProyectos.fecha.month",
          ],
          "y": [
            "measures"
          ],
          "fillMissingDates": true,
          "joinDateRange": false
        }
      })}
    />
  );
};

export default GastosProyecto;
