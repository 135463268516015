import { RoleAssignment } from "../libs/sgx/api/model";

const PARTNER: RoleAssignment = { role: 'PARTNER', businessId: -1 };
const FINANCE: RoleAssignment = { role: 'FINANCE', businessId: -1 };
const ADMINISTRATIVE: RoleAssignment = { role: 'ADMINISTRATIVE', businessId: -1 };
const HR: RoleAssignment = { role: 'HR', businessId: -1 };

export { 
    PARTNER, 
    FINANCE, 
    ADMINISTRATIVE,
    HR,
 };
