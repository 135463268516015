import React, { useState } from "react";

interface DateFilterComponentProps {
  years: string[];
  onDateRangeSelect: (year: string) => void;
}

const DateFilterComponent: React.FC<DateFilterComponentProps> = ({ years, onDateRangeSelect }) => {
  const firstYear = years[0];
  const [selectedRange, setSelectedRange] = useState<string>(firstYear);

  // Manejar el cambio de rango de fechas seleccionado
  const handleRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const year = e.target.value;
    setSelectedRange(year);
    // Notifica al componente padre el rango de fechas seleccionado
    onDateRangeSelect(year);
  };

  return (
    <div>
      <label htmlFor="dateRangeSelect">Año</label>
      <select id="dateRangeSelect" onChange={handleRangeChange} value={selectedRange}>
        <option value="" disabled>Seleccionar año</option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DateFilterComponent;
