import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import PieChartIcon from '@material-ui/icons/PieChart';
import HistoryIcon from '@material-ui/icons/History';

import PieChartField from '../../../libs/sgx/components/PieChartField';
import { Divisa } from '../../../libs/sgx/shared/currency';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface CategoriaPieProps {
  title: string;
  tipo: string;
  divisa: string;
  cerrada: string;
}

const CategoriaPie = ({
  title,
  tipo,
  divisa,
  cerrada,
}: CategoriaPieProps) => (
  <Card variant="outlined">
    <CardContent>
      <PieChartField
        title={title}
        measures={[
          "MovimientosPorEntidad.monto_abs"
        ]}
        dimensions={[
          "MovimientosPorEntidad.categoria_nombre"
        ]}
        filters={[
          {
            "member": "MovimientosPorEntidad.divisa",
            "operator": "equals",
            "values": [
              divisa
            ]
          },
          {
            "member": "MovimientosPorEntidad.gasto",
            "operator": "equals",
            "values": [
              tipo === 'gasto' ? "true" : "false",
            ]
          },
          {
            "member": "MovimientosPorEntidad.cuenta_cerrada",
            "operator": "equals",
            "values": [
              cerrada
            ]
          }
        ]}
      />
    </CardContent>
  </Card>
);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper square className={classes.root}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab icon={<PieChartIcon />} {...a11yProps(0)} />
          <Tab icon={<HistoryIcon />} {...a11yProps(1)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <Typography gutterBottom variant="h6" display="block">
          Movimientos bancarios agrupados por categoría en el balance actual
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <CategoriaPie tipo="gasto" divisa={Divisa.PESO} cerrada="false" title="Gastos en pesos"/>
          </Grid>
          <Grid item xs={12} md={6}>
            <CategoriaPie tipo="ingreso" divisa={Divisa.PESO} cerrada="false" title="Ingresos en pesos"/>
          </Grid>
          <Grid item xs={12} md={6}>
            <CategoriaPie tipo="gasto" divisa={Divisa.DOLAR} cerrada="false" title="Gastos en dólares"/>
          </Grid>
          <Grid item xs={12} md={6}>
            <CategoriaPie tipo="ingreso" divisa={Divisa.DOLAR} cerrada="false" title="Ingresos en dólares"/>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography gutterBottom variant="h6" display="block">
          Movimientos bancarios agrupados por categoría en balances cerradas
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <CategoriaPie tipo="gasto" divisa={Divisa.PESO} cerrada="true" title="Gastos en pesos"/>
          </Grid>
          <Grid item xs={12} md={6}>
            <CategoriaPie tipo="ingreso" divisa={Divisa.PESO} cerrada="true" title="Ingresos en pesos"/>
          </Grid>
          <Grid item xs={12} md={6}>
            <CategoriaPie tipo="gasto" divisa={Divisa.DOLAR} cerrada="true" title="Gastos en dólares"/>
          </Grid>
          <Grid item xs={12} md={6}>
            <CategoriaPie tipo="ingreso" divisa={Divisa.DOLAR} cerrada="true" title="Ingresos en dólares"/>
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}