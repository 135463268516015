import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    Filter, 
    ReferenceInput, 
    AutocompleteInput,
    ReferenceField,
    TextField,
} from 'react-admin';

import DolarReferenceField from '../dolar/DolarReferenceField';
import { DATE_OPTIONS } from '../../libs/sgx/shared/date';
import { divisaOptions, Divisa } from '../../libs/sgx/shared/currency';

const PersonaFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="proyectoId" reference="proyectos" >
            <AutocompleteInput optionText="nombre" />
        </ReferenceInput>
    </Filter>
);

const IngresosList = props => (
    <List {...props}filters={<PersonaFilter />} >
        <Datagrid rowClick="show">
            <ReferenceField source="proyectoId" reference="proyectos" link={false}>
                <TextField source="nombre" />
            </ReferenceField>
            <TextField source="nombre" />
            <DateField source="fechaContrato" locales="es-AR" options={DATE_OPTIONS}/> 
            <DateField source="fechaEmision" locales="es-AR" options={DATE_OPTIONS}/> 
            <DateField source="fechaCobro" locales="es-AR" options={DATE_OPTIONS}/>
            <NumberField source="montoPesos" locales="es-AR" options={divisaOptions(Divisa.PESO)}/>
            <NumberField source="montoDolares" locales="es-AR" options={divisaOptions(Divisa.DOLAR)}/>
            <DolarReferenceField label="resources.dolar.asColumn" source="fechaCobro" />
        </Datagrid>
    </List>
);

export default IngresosList;