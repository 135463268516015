import React, { useState } from 'react';

import { sgxFetchApi, jsonPayload } from '../../../libs/sgx/api/fetch';
import HttpErrorAlert from '../../../libs/sgx/components/HttpErrorAlert';
import FormCard from '../../../libs/sgx/components/FormCard';
import PasswordSetCard from './PasswordSetCard';
import FormFieldText from '../../../libs/sgx/components/FormFieldText';

const validate = values => {
    const errors = {}
    if (!values.password) {
        errors.password = 'Debe especificar una clave'
    }
    if (!values.confirm) {
        errors.confirm = 'Debe confirmar la clave'
    } else if (values.confirm !== values.password) {
        errors.confirm = 'Deben coincidir las claves'
    }
    return errors
};

const PasswordResetCard = ({ token }) => {

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(undefined);

    const handleClick = ({ password }) => {
        setLoading(true);
        const options = jsonPayload('POST', { token, password });

        sgxFetchApi('auth/password-reset', options)
            .then((data) => {
                setResult({ resultType: 'ok', payload: data });
            })
            .catch((e) => {
                setResult({ resultType: 'error', payload: e });
            })
            .finally(() => {
                setLoading(false);

            });
    };

    if (result?.resultType === 'ok') {
        return (<PasswordSetCard email={result.payload.email} />);
    }

    return (
        <FormCard title="Definir clave" submitText="Establecer" onSubmit={handleClick} submitting={loading} validate={validate}>
            <FormFieldText name="password" type="password" label="Nueva clave" />
            <FormFieldText name="confirm" type="password" label="Confirme clave" />
            {result?.resultType === 'error' && <HttpErrorAlert error={result.payload} />}
        </FormCard>
    )
};

export default PasswordResetCard;
