import React from 'react';
import {
    useTranslate,
} from 'react-admin';

import {
    Tooltip,
    IconButton,
} from '@material-ui/core';

import LaunchIcon from '@material-ui/icons/Launch';

const OpenPageButton = ({ actionClick, actionName, disabled, icon = <LaunchIcon /> }) => {
    const translate = useTranslate();
    return (
        <Tooltip title={translate(`resources.users.action.${actionName}`)}>
            <IconButton
                aria-label={translate(`resources.users.action.${actionName}`)}
                onClick={actionClick}
                disabled={disabled}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
};

export default OpenPageButton;