import React from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import LegalEntityBalance from './LegalEntityBalance';

const MovimientosCard = () => {

  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Saldos bancarios
        </Typography>
        <LegalEntityBalance />
      </CardContent>
      <CardActions>
        <Button size="small" href="#/dashboard/saldos">Mas detalles</Button>
      </CardActions>
    </Card>
  );
}

export default MovimientosCard;
