import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import {
    LIST,
    EDIT,
} from './permissions';

import BookmarkIcon from '@material-ui/icons/Bookmark';

import LegalEntityCreate from './LegalEntityCreate';
import LegalEntityList from './LegalEntityList';
import LegalEntityShow from './LegalEntityShow';
import LegalEntityEdit from './LegalEntityEdit';

const legalEntities = (permisos: SGXPermissions) => ({
    icon: BookmarkIcon,
    create: permisos.hasAnyAssignment(...EDIT) ? LegalEntityCreate : undefined,
    edit: permisos.hasAnyAssignment(...EDIT) ? LegalEntityEdit : undefined,
    list: permisos.hasAnyAssignment(...LIST) ? LegalEntityList : undefined,
    show: LegalEntityShow,
    options: {
        submenu: 'business'
    }
});

export default legalEntities;
