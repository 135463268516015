import React from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SmallBarChart from '../../dashboard/charts/SmallBarChart';

const IngresosCard = () => {

  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Ingresos a cobrar por proyecto
        </Typography>
        <div style={{ height: '120px', width: '100%' }}>

          <SmallBarChart
            measures={[
              "IngresosFuturos.monto",
            ]}
            order={{
              "IngresosFuturos.monto": "desc"
            }}
            dimensions={[
              "IngresosFuturos.proyecto_nombre"
            ]}
            pivotConfigX="IngresosFuturos.proyecto_nombre"
          />
        </div>
      </CardContent>
      <CardActions>
        <Button size="small" href="#/dashboard/ingresos">Mas detalles</Button>
      </CardActions>
    </Card>
  );
}

export default IngresosCard;
