import React, { MouseEvent, useRef } from 'react';
import cubejs, {
  ChartPivotRow,
  Filter,
  ResultSet,
  Series,
  TimeDimension,
  TQueryOrderArray,
  TQueryOrderObject
} from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Chart as ChartJS, InteractionItem, registerables } from 'chart.js';
import {
  Chart,
  getElementAtEvent,
} from 'react-chartjs-2';

import { cubejsApiTokenPromise, cubejsOptions } from './cube-config';
import { 
  monthFormatter,
  dateISOFormatter,
} from '../../../libs/sgx/shared/date';

const COLORS_SERIES = ['#FF6492', '#141446', '#7A77FF'];

const commonOptions = {
  maintainAspectRatio: false,
};

const cubejsApi = cubejs(cubejsApiTokenPromise, cubejsOptions);

ChartJS.register(...registerables);

interface TimeBarChartProps {
  measures: string[];
  dimensions?: string[];
  timeDimensions: TimeDimension[];
  order?: TQueryOrderObject | TQueryOrderArray;
  filters?: Filter[];
  onClick: (value: string) => void;
  labels: Record<string, string>;
}

interface TimeBarComponentProps {
  resultSet: ResultSet;
  onClick: (value: string | undefined) => void;
  labels: Record<string, string>;
}

function TimeBarComponent({
  resultSet,
  onClick,
  labels,
}: TimeBarComponentProps) {
  
  const fechas = resultSet.categories().map((c: ChartPivotRow) => dateISOFormatter(c.x));

  const data = {
    labels: resultSet.categories().map((c: ChartPivotRow) => monthFormatter(c.x)),
    datasets: resultSet.series().map((s: Series<any>, index: number) => ({
      data: s.series.map((r) => r.value),
      backgroundColor: COLORS_SERIES[index],
      borderColor: COLORS_SERIES[index],
      label: labels ? labels[s.key] : s.key,
    })),

  };
  const options = {
    ...commonOptions,
    plugins: {
      legend: {
        display: !!labels
      },
    },
  };

  const getEventFecha = (element: InteractionItem[]): string | undefined => {
    if (!element.length) return undefined;
    const { index } = element[0];

    return fechas[index];
  };

  const chartRef = useRef<ChartJS>(null);

  const clickHandler = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;

    if (!chart) {
      console.warn('no chart found to get event data');
      return;
    }

    if (onClick !== undefined) {
      const fecha = getEventFecha(getElementAtEvent(chart, event));
      onClick(fecha);
    }
  };

  return (
    <div style={{ height: '230px' }}>
      <Chart
        ref={chartRef}
        type='bar'
        onClick={clickHandler}
        options={options}
        data={data}
      />
    </div>
  );
}

const renderChart = ({
  resultSet,
  error,
  onClick,
  labels,
}: any) => {

  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return <LinearProgress />;
  }

  return (
    <TimeBarComponent resultSet={resultSet} onClick={onClick} labels={labels}/>
  );

};

const TimeBarChart = ({
  measures,
  dimensions,
  timeDimensions,
  order,
  filters = [],
  onClick,
  labels,
}: TimeBarChartProps) => {

  return (
    <QueryRenderer
      query={{
        measures,
        dimensions,
        timeDimensions,
        order,
        filters,
      }}
      cubejsApi={cubejsApi}
      resetResultSetOnChange={false}
      render={(props) => renderChart({ 
        onClick,
        labels,
        ...props
      })}
    />
  );
};

export default TimeBarChart;
