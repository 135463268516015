import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { 
    monthFormatter,
  } from '../../libs/sgx/shared/date';
import { currencyFormatter, Divisa, genericCurrency } from '../../libs/sgx/shared/currency';
import ChartTableField from '../../libs/sgx/components/ChartTableField';
import RowChartField from '../../libs/sgx/components/RowChartField';
import RowChartFieldData from '../../libs/sgx/components/RowChartFieldData';
import PieChartField from '../../libs/sgx/components/PieChartField';
import TimeBarChart from '../../layout/dashboard/charts/TimeBarChart';

const CategoriaPie = ({
    title,
    tipo,
    divisa,
    fecha,
    entidadId,
}) => {
    const filters = [
        {
            "member": "MovimientosPorCategoriaPorEntidadPorMes.divisa",
            "operator": "equals",
            "values": [
                divisa
            ]
        },
        {
            "member": "MovimientosPorCategoriaPorEntidadPorMes.gasto",
            "operator": "equals",
            "values": [
                tipo === 'gasto' ? "true" : "false"
            ]
        },
        {
            "member": "MovimientosPorCategoriaPorEntidadPorMes.entidad_id",
            "operator": "equals",
            "values": [
                entidadId
            ]
        }
    ];
    if (fecha) {
        filters.push({
            "member": "MovimientosPorCategoriaPorEntidadPorMes.fecha",
            "operator": "equals",
            "values": [
                fecha
            ]
        });
    }
    return (
        <PieChartField
            title={fecha ? `${title} en ${monthFormatter(fecha)}` : title}
            measures={[
                "MovimientosPorCategoriaPorEntidadPorMes.monto_abs"
            ]}
            dimensions={[
                "MovimientosPorCategoriaPorEntidadPorMes.categoria_nombre"
            ]}
            filters={filters}
        />
    )
};

const LegalEntityCuentas = ({
    id,
    divisa,
}) => {
    const [fecha, setFecha] = useState(null);

    const emoji = divisa === Divisa.DOLAR ? '🇺🇸' : '🇦🇷';
    const source = divisa === Divisa.DOLAR ? 'monto_dolares' : 'monto_pesos';

    const fechaHandler = (newFecha) => {
        setFecha(newFecha === fecha ? null : newFecha);
    };

    return (
        <div>
            <RowChartField
                title="Saldo total en cuentas bancarias"
                cube="MovimientosPorEntidad"
                filters={[
                    {
                        "member": "MovimientosPorEntidad.entidad_id",
                        "operator": "equals",
                        "values": [
                            id
                        ]
                    }
                ]}
                measures={[
                    "monto",
                ]}
                dimensions={[
                    "divisa",
                ]}
                pivotConfig={{
                    "x": [],
                    "y": [
                        "MovimientosPorEntidad.divisa",
                        "measures"
                    ],
                    "fillMissingDates": true,
                    "joinDateRange": false
                }}
            >
                <RowChartFieldData icon={emoji} source={source} calculate={(row) => row && parseInt(row[`${divisa},MovimientosPorEntidad.monto`] || 0, 10)} format={genericCurrency} />
            </RowChartField>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <ChartTableField
                        title="Saldos en cada cuenta bancaria"
                        measures={[
                            "MovimientosPorEntidad.monto"
                        ]}
                        dimensions={[
                            "MovimientosPorEntidad.cuenta_nombre",
                            "MovimientosPorEntidad.divisa"
                        ]}
                        filters={[
                            {
                                "member": "MovimientosPorEntidad.divisa",
                                "operator": "equals",
                                "values": [
                                    divisa
                                ]
                            },
                            {
                                "member": "MovimientosPorEntidad.entidad_id",
                                "operator": "equals",
                                "values": [
                                    id
                                ]
                            }
                        ]}
                        columns={[
                            {
                                "field": "MovimientosPorEntidad.cuenta_nombre",
                                "headerName": "Cuenta",
                                "width": 200
                            },
                            {
                                "field": "MovimientosPorEntidad.monto",
                                "headerName": "Monto",
                                "width": 200,
                                align: 'right',
                                "valueGetter": ({ row }) => parseInt(row['MovimientosPorEntidad.monto'], 10),
                                valueFormatter: ({ value, row }) => currencyFormatter(row['MovimientosPorEntidad.divisa'])(value),
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="inherit" color="textSecondary">
                        Gasto total mensual
                    </Typography>
                    <TimeBarChart
                        measures={[
                            "MovimientosPorCategoriaPorEntidadPorMes.monto_abs"
                        ]}
                        timeDimensions={[
                            {
                                "dimension": "MovimientosPorCategoriaPorEntidadPorMes.fecha",
                                "granularity": "month",
                                "dateRange": "This year"
                            }
                        ]}
                        pivotConfigX="MovimientosPorCategoriaPorEntidadPorMes.fecha.month"
                        filters={[
                            {
                                "member": "MovimientosPorCategoriaPorEntidadPorMes.gasto",
                                "operator": "equals",
                                "values": [
                                    "true"
                                ]
                            },
                            {
                                "member": "MovimientosPorCategoriaPorEntidadPorMes.divisa",
                                "operator": "equals",
                                "values": [
                                    divisa
                                ]
                            },
                            {
                                "member": "MovimientosPorCategoriaPorEntidadPorMes.entidad_id",
                                "operator": "equals",
                                "values": [
                                    id
                                ]
                            },
                        ]}
                        onClick={fechaHandler}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CategoriaPie tipo="gasto" divisa={divisa} cerrada="true" title="Gastos" entidadId={id} fecha={fecha}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CategoriaPie tipo="ingreso" divisa={divisa} cerrada="true" title="Ingresos" entidadId={id} fecha={fecha}/>
                </Grid>
            </Grid>

        </div>
    )
};

export default LegalEntityCuentas;
