import React from 'react';
import { useParams } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import PasswordResetCard from './PasswordResetCard';

const PasswordResetPage = () => {
  const { token } = useParams();

  return (
    <Container maxWidth="sm" style={{ marginTop: '10vh' }}>
      <PasswordResetCard token={token} />
    </Container>
  )
};

export default PasswordResetPage;
