import React from 'react';
import { Admin } from 'react-admin';

import {
    dataProvider,
    authProvider,
    i18nProviderBuilder,
} from './libs/sgx/api';

import { 
    Dashboard,
    Layout,
} from './layout';

import { customRoutes } from './layout/routes';
import resources from './modules';
import messages from './providers/i18n';

const App = () => (
    <Admin
        authProvider={authProvider}
        customRoutes={customRoutes}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        disableTelemetry
        i18nProvider={i18nProviderBuilder(messages)}
        layout={Layout}
        title="Lamansers" >
        {permissions => resources(permissions)}
    </Admin>
);

export default App;
