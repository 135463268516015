import React from 'react';
import {
    Show,
    Pagination,
    TextField,
    DateField,
    SimpleShowLayout, 
    ReferenceManyField,
    Datagrid,
    SimpleList,
    ReferenceField,
    NumberField,
    CreateButton,
} from 'react-admin';
import { TopToolbar, EditButton } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import CuentaResumen from './CuentaResumen';
import ImportButton from '../../libs/sgx/components/ImportButton';
import { genericCurrency } from '../../libs/sgx/shared/currency';
import MovimientosRowEdit from '../movimientos/MovimientosRowEdit';

const movimientoRowStyle = (record, index) => ({
    backgroundColor: record.monto >= 0 ? '#efe' : '',
});

const CuentaShowActions = ({ basePath, data }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        <CreateButton basePath="/movimientos" label="resources.cuentas.buttons.movimiento-create" record={data} to={{ pathname: `/movimientos/create`, state: { record: {cuentaId: data?.id } } }}/>
        <ImportButton resource="movimientos" record={{cuentaId: data?.id, format: 'prueba'}}/>
    </TopToolbar>
);

const CuentaShow = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <Show {...props} actions={<CuentaShowActions />}>
        <SimpleShowLayout>
            <ReferenceField source="legalEntityId" reference="legal-entities" link="show">
                <TextField source="name" />
            </ReferenceField>
            <CuentaResumen></CuentaResumen>
            <ReferenceManyField reference="movimientos" target="cuentaId" addLabel={false} 
                sort={{ field: 'fecha', order: 'DESC' }}
                pagination={<Pagination />}>
                {isSmall ? (<SimpleList
                    primaryText={record => record.detalle}
                    secondaryText={<DateField source="fecha" locales="es-AR" options={{ timeZone: 'UTC' }}/>}
                    tertiaryText={record => genericCurrency(record.monto)}
                    linkType="edit"
                />
                ) : (
                    <Datagrid rowStyle={movimientoRowStyle} rowClick="expand" expand={<MovimientosRowEdit />}>
                        <DateField source="fecha" locales="es-AR" options={{ timeZone: 'UTC' }}/>
                        <TextField source="detalle" />
                        <NumberField source="monto" locales="es-AR" options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}/>
                        <ReferenceField source="categoriaId" reference="categoria-movimiento">
                            <TextField source="nombre" />
                        </ReferenceField>
                        <ReferenceField source="proyectoId" reference="proyectos">
                            <TextField source="nombre" />
                        </ReferenceField>
                    </Datagrid>
                )}
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
)};

export default CuentaShow;