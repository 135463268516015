import React from 'react';
import {
    List,
    Filter,
    SearchInput,
    DateInput,
    SimpleList,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

import { dayFormatter } from '../../libs/sgx/shared/date';

const fechaFin = new Date().toISOString().substring(0, 10);

const ProyectoFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput source="fechaFin" alwaysOn />
        <ReferenceInput source="businessUnitId"
            alwaysOn
            reference="business-units"
            sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="legalEntityId"
            reference="legal-entities"
            sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
    </Filter>
);

const ProyectoList = props => (
    <List {...props}
        filters={<ProyectoFilter />}
        filterDefaultValues={{ fechaFin }}
        bulkActionButtons={false}
    >
        <SimpleList
            primaryText={record => `💼 ${record.nombre}`}
            secondaryText={record => dayFormatter(record.fechaInicio)}
            tertiaryText={record => record.fechaFin ? `Termina el ${dayFormatter(record.fechaFin)}` : '...'}
            linkType="show"
        />
    </List>
);

export default ProyectoList;