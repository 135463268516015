import { RoleAssignment } from '../../libs/sgx/api/model';

import {
    ADMINISTRATIVE,
    FINANCE,
    PARTNER,
} from '../roles';

const LIST:  RoleAssignment[] = [
    ADMINISTRATIVE,
    FINANCE,
    PARTNER,
];

const EDIT:  RoleAssignment[] = [
    FINANCE,
    PARTNER,
];

export { 
    LIST,
    EDIT,
 };
