import React from 'react';
import {
    SimpleList,
    List
} from 'react-admin';

const LegalEntityList = props => (
    <List {...props}>
        <SimpleList
            primaryText={record => `🏷 ${record.name}`}
            linkType="show"
        />
    </List>
);

export default LegalEntityList;