import { RoleAssignment } from '../../libs/sgx/api/model';

import {
    ADMINISTRATIVE,
    FINANCE,
    PARTNER,
} from '../roles';

const LIST:  RoleAssignment[] = [
    ADMINISTRATIVE,
    FINANCE,
    PARTNER,
];

export { 
    LIST, 
 };
