import React from 'react';
import {
    List,
    Filter,
    ReferenceInput,
    Datagrid,
    TextField,
    ReferenceField,
    TextInput,
    AutocompleteInput,
} from 'react-admin';

import PersonaField from '../personas/PersonaField';
import MontoField from '../cuentas/MontoField';
import { DIVISA_CURRENCY } from '../cuentas/utils';
import { fullName } from '../personas/utils';

const fechaFin = new Date().toISOString().substring(0,10);

const RetribucionPivotFilter = (props) => (
    <Filter {...props}>
        <TextInput source="anio" alwaysOn />
        <TextInput source="mes" alwaysOn />
        <ReferenceInput source="personaId" reference="personas">
            <AutocompleteInput optionText={fullName} />
        </ReferenceInput>
    </Filter>
);

const RetribucionPivotList = props => (
    <List {...props}
        filters={<RetribucionPivotFilter />}
        filterDefaultValues={{ fechaFin }} 
        bulkActionButtons={false}
    >
        <Datagrid rowClick="show" >
            <TextField source="anio" />
            <TextField source="mes" />
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <MontoField source="sueldoPesos" divisa={DIVISA_CURRENCY.PESO}/>
            <MontoField source="sueldoDolares" divisa={DIVISA_CURRENCY.DOLAR}/>
        </Datagrid>
    </List>
);

export default RetribucionPivotList;