import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { ADMINISTRATIVE, FINANCE, PARTNER } from '../roles';

import PaymentIcon from '@material-ui/icons/LocalAtm';

import PagoEjecutadoCreate from './PagoEjecutadoCreate';
import PagoEjecutadoList from './PagoEjecutadoList';
import PagoEjecutadoShow from './PagoEjecutadoShow';
import PagoEjecutadoEdit from './PagoEjecutadoEdit';

export const pagosEjecutadosPermissions = [
    ADMINISTRATIVE,
    FINANCE,
    PARTNER,
];

const pagosEjecutados = (permisos: SGXPermissions) => ({
    icon: PaymentIcon,
    create: PagoEjecutadoCreate,
    edit: PagoEjecutadoEdit,
    list: permisos.hasAnyAssignment(...pagosEjecutadosPermissions) ? PagoEjecutadoList : undefined,
    show: PagoEjecutadoShow,
    options: {
        submenu: 'staff'
    }
});

export default pagosEjecutados;
