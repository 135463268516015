// in src/Foo.js
import * as React from "react";

import RowChartField from '../../../libs/sgx/components/RowChartField';
import RowChartFieldData from '../../../libs/sgx/components/RowChartFieldData';
import { genericCurrency } from '../../../libs/sgx/shared/currency';

const LegalEntityBalance = (props) => (
    <div>
        <RowChartField
              cube="MovimientosPorEntidad"
              filters={[
                  {
                      "member": "MovimientosPorEntidad.cuenta_cerrada",
                      "operator": "equals",
                      "values": [
                          "false"
                      ]
                  },
              ]}
              measures={[
                  "monto",
              ]}
              dimensions={[
                "entidad_nombre",
                "divisa",
              ]}
              pivotConfig={{
                  "x": [
                    "MovimientosPorEntidad.entidad_nombre"
                  ],
                  "y": [
                      "MovimientosPorEntidad.divisa",
                      "measures"
                  ],
                  "fillMissingDates": true,
                  "joinDateRange": false
              }}
          >
              <RowChartFieldData source="entidad_nombre" />
              <RowChartFieldData icon="🇦🇷" source="monto_pesos" calculate={(row) => row && parseInt(row['PESO,MovimientosPorEntidad.monto'] || 0, 10)} format={genericCurrency} />
              <RowChartFieldData icon="🇺🇸" source="monto_dolares" calculate={(row) => row && parseInt(row['DOLAR,MovimientosPorEntidad.monto'] || 0, 10)} format={genericCurrency} />
          </RowChartField>
    </div>
);

export default LegalEntityBalance;