import React, {useState} from 'react';
import { useRecordContext } from 'react-admin';

import Typography from '@material-ui/core/Typography';

import { YEAR_RANGE, RatesPorJob } from '../../layout/dashboard/index';
import DateFilterComponent from '../../libs/sgx/components/DateFilterComponent';

const JobRates = (props) => {
    const [selectedYear, setSelectedYear] = useState(YEAR_RANGE[0]);
    const { source } = props;
    const record = useRecordContext(props);
    const jobId = record[source];

    const jobIdFilters = [
        {
            "member": "ValorHoraPorJob.job_id",
            "operator": "equals",
            "values": [
                `${jobId}`
            ]
        }
    ];

    const dateFilters = [
        {
            "member": "ValorHoraPorJob.fecha",
            "operator": "inDateRange",
            "values": [`${selectedYear}-01-01`,`${selectedYear}-12-31`],
        },
    ];

    const timeDimensions = [
        {
            "dimension": "ValorHoraPorJob.fecha",
            "granularity": "month",
        }
    ];

    const handleDateRangeSelect = (year) => {
        setSelectedYear(year);
    };

    return (
        <React.Fragment>
            <Typography gutterBottom variant="h6" display="block">
                Rates por Persona
            </Typography>
            <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
            <div style={{ height: '70vh' }}>
                {jobId >=0 && <RatesPorJob
                    measures={[
                        "ValorHoraPorJob.valor_hora",
                    ]}
                    timeDimensions={timeDimensions}
                    filters={[...dateFilters, ...jobIdFilters]}
                />
                }
            </div>
        </React.Fragment>
    );

}

export default JobRates;
