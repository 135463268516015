import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import {
    LIST,
} from './permissions';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { AsignacionesCreate } from './AsignacionesCreate';
import { AsignacionesShow } from './AsignacionesShow';
import { AsignacionesEdit } from './AsignacionesEdit';
import AsignacionesList from './AsignacionesList';

const asignaciones = (permisos: SGXPermissions) => ({
    icon: AssignmentIndIcon,
    create: AsignacionesCreate,
    edit: AsignacionesEdit,
    list: permisos.hasAnyAssignment(...LIST) ? AsignacionesList : undefined,
    show: AsignacionesShow,
    options: {
        submenu: 'staff'
    }
});

export default asignaciones;
