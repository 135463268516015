import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
} from 'react-admin';

import PersonaField from '../personas/PersonaField';

export const AsignacionesShow = props => (
    <Show {...props} >
        <SimpleShowLayout>
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <ReferenceField source="proyectoId" reference="proyectos" link="show">
                <TextField source="nombre" />
            </ReferenceField>
            <ReferenceField source="jobId" reference="jobs" link={null}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="horasMensuales" />
            <TextField source="detalle" />
            <TextField source="fechaInicio" />
            <TextField source="fechaFin" />
        </SimpleShowLayout>
    </Show>
);
