import { retrieveToken } from '../../../libs/sgx/api/tokenStorage';
import { CubeJSApiOptions } from '@cubejs-client/core';

// Los tableros generados con cubejs se inicializan al cargar la aplicación

// Para agregar un nuevo gráfico:

// 1. se debería crear el componente Card

// 2. se debería modificar el código del gráfico para exportar el componente, por ejemplo:
//  > Se reemplaza el código:
//      > const rootElement = document.getElementById('root');
//      > ReactDOM.render(<ChartRenderer />, rootElement);
//  > Por el export:
//      > export default ChartRenderer;

// 3. se debería cambiar la definición de `cubejsApi` por:
//  > import { cubejsApiTokenPromise, cubejsOptions } from './cube-config';
//  > const cubejsApi = cubejs(cubejsApiTokenPromise, cubejsOptions);
  

const cubejsApiTokenPromise = () => Promise.resolve(retrieveToken() || '');

const cubejsOptions: CubeJSApiOptions = {
    apiUrl: '/api/dashboards/cubejs-api/v1',
};

export { cubejsApiTokenPromise, cubejsOptions };