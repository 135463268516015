import React from 'react';
import {
    List,
    Filter,
    ReferenceInput,
    Datagrid,
    TextField,
    ReferenceField,
    TextInput,
    AutocompleteInput,
} from 'react-admin';

import PersonaField from '../personas/PersonaField';
import { fullName } from '../personas/utils';

const fechaFin = new Date().toISOString().substring(0,10);

const AsignacionContratadaPivotFilter = (props) => (
    <Filter {...props}>
        <TextInput source="anio" alwaysOn />
        <TextInput source="mes" alwaysOn />
        <ReferenceInput source="personaId" reference="personas">
            <AutocompleteInput optionText={fullName} />
        </ReferenceInput>
    </Filter>
);

const AsignacionContratadaPivotList = props => (
    <List {...props}
        filters={<AsignacionContratadaPivotFilter />}
        filterDefaultValues={{ fechaFin }} 
        bulkActionButtons={false}
    >
        <Datagrid rowClick={null} >
            <TextField source="anio" />
            <TextField source="mes" />
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <TextField source="horasMensuales" />
        </Datagrid>
    </List>
);

export default AsignacionContratadaPivotList;