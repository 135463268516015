import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
} from 'react-admin';
import { LIST as MOVIMIENTOS_LIST } from '../movimientos/permissions';
import TypographyField from '../../libs/sgx/components/TypographyField';
import ShowFormTitle from '../../libs/sgx/components/ShowFormTitle';
import LegalEntityCuentas from './LegalEntityCuentas';

import { Divisa } from '../../libs/sgx/shared/currency';

const LegalEntityShow = props => {
    const accesoAMovimientos = props.permissions?.hasAnyAssignment(...MOVIMIENTOS_LIST);
    return (
        <Show {...props} title={<ShowFormTitle mapper={legalEntity => legalEntity?.name} />}>
            <TabbedShowLayout>
                <Tab label="Resumen">
                    <TypographyField source="name" variant="h4"></TypographyField>
                </Tab>
                <Tab label="🇦🇷">
                    {accesoAMovimientos && <LegalEntityCuentas divisa={Divisa.PESO} {...props} />}
                </Tab>
                <Tab label="🇺🇸">
                    {accesoAMovimientos && <LegalEntityCuentas divisa={Divisa.DOLAR} {...props} />}
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

export default LegalEntityShow;