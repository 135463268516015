import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';

import { cubejsApiTokenPromise, cubejsOptions } from '../../../layout/dashboard/charts/cube-config';

const cubejsApi = cubejs(cubejsApiTokenPromise, cubejsOptions);

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    flexWrap: 'wrap',
  },
  title: {
    fontSize: 14,
  },
});

const renderChart = ({ children, resultSet, error, pivotConfig, cube, classes, title }) => {

  if (error) {
    return <div>{error.toString()}</div>;
  }
  if (!resultSet) {
    return <div></div>;
  }

  if (!children) {
    return <div>Nada para mostrar</div>;
  }
  // const columns = resultSet.tableColumns(pivotConfig);
  const dataSource = resultSet.tablePivot(pivotConfig);
  // console.log('dataSource', dataSource.length);
  const count = dataSource.length;

  if (!count) {
    return <div></div>;
  }

  return (
    <div>
      <Typography variant="inherit" color="textSecondary">
          {title}
      </Typography>
      {dataSource.map((item, index) => (
        <div className={classes.root} key={`elem_${index}`}>
          {React.Children.map(children || null, (child, i) => {
            return React.cloneElement(child, { data: item, cube, count });
          })}
        </div>
      ))}
    </div>
  );

};

const RowChartField = ({ cube, measures, filters, children, dimensions = [], order, pivotConfig, title, ...props }) => {
  const classes = useStyles();
  const queryMeasures = measures.map(item => `${cube}.${item}`);
  const queryDimensions = dimensions.map(item => `${cube}.${item}`);

  return (
    <QueryRenderer
      query={{
        "measures": queryMeasures,
        "timeDimensions": [],
        "order": order,
        "dimensions": queryDimensions,
        "filters": filters,
      }}
      cubejsApi={cubejsApi}
      resetResultSetOnChange={false}
      render={(props) => renderChart({
        ...props,
        children,
        pivotConfig,
        cube,
        classes,
        title,
      })}
    />
  );
};

export default RowChartField;
