import React from 'react';
import PropTypes from 'prop-types';
import {
    ReferenceField,
    FunctionField,
} from 'react-admin';
import { fullName } from './utils';

const PeopleReferenceField = (props) => (
    <ReferenceField reference="personas" link="show" {...props} >
        <FunctionField render={fullName}/>
    </ReferenceField>
);

PeopleReferenceField.propTypes = {
    record: PropTypes.object,
};

export default PeopleReferenceField;
