import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { FINANCE, PARTNER } from '../roles';

import FormatPaintIcon from '@material-ui/icons/FormatPaint';

import AsignacionPivotList from './AsignacionPivotList';

export const asignacionPivotPermissions = [
    FINANCE,
    PARTNER,
];

const asignacionPivot = (permisos: SGXPermissions) => ({
    icon: FormatPaintIcon,
    list: permisos.hasAnyAssignment(...asignacionPivotPermissions) ? AsignacionPivotList : undefined,
    options: {
        submenu: 'pivot'
    }
});

export default asignacionPivot;