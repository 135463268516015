import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    DateField,
} from 'react-admin';

import PersonaField from '../personas/PersonaField';
import { DATE_OPTIONS } from '../../libs/sgx/shared/date';

export const AsignacionescontratadasShow = props => (
    <Show {...props} >
        <SimpleShowLayout>
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <TextField source="horasMensuales" />
            <TextField source="detalle" />
            <DateField source="fechaInicio" locales="es-AR" options={DATE_OPTIONS}/> 
            <DateField source="fechaFin" locales="es-AR" options={DATE_OPTIONS}/> 
        </SimpleShowLayout>
    </Show>
);
