import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';

import UserCreate from './UserCreate';
import UserList from './UserList';
import UserEdit from './UserEdit';

const users = (permisos: SGXPermissions) => ({
    icon: VerifiedUserIcon,
    list: permisos.hasAnyAssignment({role: 'PARTNER', businessId: -1}) ? UserList : undefined,
    create: UserCreate,
    edit: UserEdit,
});

export default users;