import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { FINANCE, PARTNER } from '../roles';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import CuentaCreate from './CuentaCreate';
import CuentasList from './CuentasList';
import CuentaShow from './CuentaShow';
import CuentaEdit from './CuentaEdit';

export const cuentasPermissions = [
    FINANCE,
    PARTNER,
];

const cuentas = (permisos: SGXPermissions) => ({
    icon: AccountBalanceIcon,
    create: CuentaCreate,
    edit: CuentaEdit,
    list: permisos.hasAnyAssignment(...cuentasPermissions) ? CuentasList : undefined,
    show: CuentaShow,
    options: {
        submenu: 'banking'
    }
    
});

export default cuentas;
