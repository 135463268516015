import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { PARTNER, FINANCE, ADMINISTRATIVE, HR } from '../roles';

import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import JobCreate from './JobCreate';
import JobList from './JobList';
import JobShow from './JobShow';
import JobEdit from './JobEdit';

export const jobsPermissions = [
    ADMINISTRATIVE,
    FINANCE,
    HR,
    PARTNER,
];

const jobs = (permisos: SGXPermissions) => ({
    icon: LocalOfferIcon,
    create: JobCreate,
    edit: JobEdit,
    list: permisos.hasAnyAssignment(...jobsPermissions) ? JobList : undefined,
    show: JobShow,
});

export default jobs;
