import React from 'react';
import {
    ReferenceInput,
    DateInput,
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    required,
    AutocompleteInput,
} from 'react-admin';

import { fullName } from '../cuentas/utils';

const MovimientosEdit = props => (
    <Edit {...props}>
        <SimpleForm >
            <ReferenceInput source="cuentaId" reference="cuentas" sort={{ field: 'fin', order: 'DESC' }} validate={[required()]}>
                <AutocompleteInput optionText={fullName} />
            </ReferenceInput>

            <DateInput source="fecha" validate={[required()]}/>
            <TextInput source="detalle" />
            <NumberInput source="monto" locales="es-AR" validate={[required()]}/>
            <ReferenceInput source="categoriaId" reference="categoria-movimiento" allowEmpty>
                <AutocompleteInput optionText="nombre" />
            </ReferenceInput>
            <ReferenceInput source="proyectoId" reference="proyectos" allowEmpty>
                <AutocompleteInput optionText="nombre" />
            </ReferenceInput>
            <TextInput source="comentarios" multiline />
        </SimpleForm>
    </Edit>
);

export default MovimientosEdit;