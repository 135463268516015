import React from 'react';
import {
    Edit,
    SimpleForm,
    BooleanInput,
    TextInput,
    ArrayInput,
    DateField,
    SimpleFormIterator,
    ReferenceInput,
    SelectInput,
    required,
    AutocompleteInput,
} from 'react-admin';

import OnlySaveToolbar from '../components/only-save-toolbar';
import UserAside from './UserAside'
import { fullName } from '../personas/utils';

const UserEdit = props => (
    <Edit {...props} 
        aside={<UserAside />} 
    >
        <SimpleForm toolbar={<OnlySaveToolbar />}>
            <TextInput source="username" validate={[required()]}/>
            <BooleanInput source="enable"/>
            <DateField source="lastLogin" showTime locales="es-AR"/>
            <ReferenceInput
                reference="personas"
                source="personaId"
                enableGetChoices={({ q }) => (q ? q.length >= 2 : false)}
            >
                <AutocompleteInput 
                    optionText={fullName}
                />
            </ReferenceInput>

            <ArrayInput source="roles0">
                <SimpleFormIterator>
                    <ReferenceInput source="roleId" reference="roles" filter={{level:'LEVEL0'}} validate={[required()]}>
                        <SelectInput optionText="description" optionValue="id"/>
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="roles1">
                <SimpleFormIterator>
                    <ReferenceInput source="roleId" reference="roles" filter={{level:'LEVEL1'}} validate={[required()]}>
                        <SelectInput optionText="description" optionValue="id"/>
                    </ReferenceInput>
                    <ReferenceInput
                        source="businessId"
                        reference="business-unit"
                        sort={{ field: 'name', order: 'ASC' }}
                        validate={[required()]}
                    >
                        <SelectInput optionText="name" optionValue="id"/>
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export default UserEdit;
