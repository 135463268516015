import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import TimeBarChart from '../../layout/dashboard/charts/TimeBarChart';

const IngresosPorMesBarChart = (props) => {

    const projectId = props?.record?.id;

    if (!projectId) {
        return <span></span>;
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Typography variant="inherit" color="textSecondary">
                    Ingresos en pesos
                </Typography>
                <TimeBarChart
                    labels={{
                        "ingresado,IngresosProyectos.monto_pesos": "Ingresado",
                        "contrato,IngresosProyectos.monto_pesos": "Contrato",
                    }}
                    measures={[
                        "IngresosProyectos.monto_pesos"
                    ]}
                    dimensions={[
                        "IngresosProyectos.tipo"
                    ]}
                    timeDimensions={[
                        {
                            "dimension": "IngresosProyectos.fecha",
                            "granularity": "month",
                            "dateRange": "This year"
                        }
                    ]}
                    order={{
                        "IngresosProyectos.monto_pesos": "desc"
                    }}
                    filters={[
                        {
                            "member": "IngresosProyectos.proyecto_id",
                            "operator": "equals",
                            "values": [
                                `${projectId}`
                            ]
                        },
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="inherit" color="textSecondary">
                    Ingresos en dolares
                </Typography>
                <TimeBarChart
                    labels={{
                        "ingresado,IngresosProyectos.monto_dolares": "Ingresado",
                        "contrato,IngresosProyectos.monto_dolares": "Contrato",
                    }}
                    measures={[
                        "IngresosProyectos.monto_dolares"
                    ]}
                    dimensions={[
                        "IngresosProyectos.tipo"
                    ]}
                    timeDimensions={[
                        {
                            "dimension": "IngresosProyectos.fecha",
                            "granularity": "month",
                            "dateRange": "This year"
                        }
                    ]}
                    order={{
                        "IngresosProyectos.monto_dolares": "desc"
                    }}
                    filters={[
                        {
                            "member": "IngresosProyectos.proyecto_id",
                            "operator": "equals",
                            "values": [
                                `${projectId}`
                            ]
                        }
                    ]}
                />
            </Grid>
        </Grid>
    )
};

export default IngresosPorMesBarChart;
