import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';

const CategoriaMovimientoEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nombre" validate={[required()]}/>
        </SimpleForm>
    </Edit>
);

export default CategoriaMovimientoEdit;
