import React from 'react';
import PropTypes from 'prop-types';
import {
    ReferenceField,
    FunctionField,
    
} from 'react-admin';
import { bothValues } from './utils';

const DolarReferenceField = (props) => (
    <ReferenceField reference="dolar" link={false} {...props} >
        <FunctionField render={bothValues}/>
    </ReferenceField>
);

DolarReferenceField.propTypes = {
    record: PropTypes.object,
};

export default DolarReferenceField;
