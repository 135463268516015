import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    TabbedShowLayoutTabs,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import ShowFormTitle from '../../libs/sgx/components/ShowFormTitle';

import {
    LIST as RETRIBUCION_LIST,
} from '../retribuciones/permissions';

import JobRates from './JobRates';

const useTabsStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100vw',
    },
}));

const jobName = (job = {}) => job?.name;

const JobShow = props => {
    const accesoARetribuciones = props.permissions?.hasAnyAssignment(...RETRIBUCION_LIST);
    const classes = useTabsStyles();
    return (
    <Show {...props} title={<ShowFormTitle mapper={jobName}/>} >
        <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="on" className={classes.root} />}>
            <Tab label="resources.jobs.tabs.general">
                <TextField source="name" />

                {accesoARetribuciones && <Tab label="resources.proyectos.tabs.grafico" path="grafico">
                    <JobRates source="id"/>
                </Tab>}
            </Tab>
        </TabbedShowLayout>
    </Show>
)
};

export default JobShow;