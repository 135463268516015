import React from 'react';

import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';

import LinearProgress from '@material-ui/core/LinearProgress';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { cubejsApiTokenPromise, cubejsOptions } from './cube-config';
import { monthCapitalFormatter } from '../../../libs/sgx/shared/date';
import { firstPart } from '../../../libs/sgx/shared/text';

const COLORS_SERIES = ['#FF6492', '#141446', '#7A77FF', '#B0171F', '#DC143C', '#FFB6C1', '#551A8B', '#6A5ACD', '#E6E6FA'];
const commonOptions = {
    maintainAspectRatio: false,
};


const cubejsApi = cubejs(cubejsApiTokenPromise, cubejsOptions);

ChartJS.register(...registerables);

const renderChart = ({ resultSet, error, pivotConfig }) => {
    if (error) {
        return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
        return <LinearProgress />;
    }

    const mapLabel = firstPart(',ValorHoraPorJob');

    const data = {
        labels: resultSet.categories().map((c) => monthCapitalFormatter(c.x)),
        datasets: resultSet.series().map((s, index) => ({
            label: mapLabel(s.key),
            data: s.series.map((r) => r.value),
            backgroundColor: COLORS_SERIES[index],
            borderColor: COLORS_SERIES[index],
            fill: false,
        })),
    };
    const options = { ...commonOptions };
    return <Line data={data} options={options} />;

};

const ChartRenderer = ({
    measures,
    filters,
    timeDimensions,
  }) => {
    return (
        <QueryRenderer
            query={{
                measures,
                timeDimensions,
                "order": {},
                "dimensions": [
                    "ValorHoraPorJob.persona_nombre"
                ],
                filters,
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) => renderChart({
                ...props,
                chartType: 'line',
                pivotConfig: {
                    "x": [
                        "ValorHoraPorJob.persona_nombre",
                        "ValorHoraPorJob.fecha.month"
                    ],
                    "y": [
                        "measures"
                    ],
                    "fillMissingDates": true,
                    "joinDateRange": false
                }
            })}
        />
    );
};

export default ChartRenderer;