
const fullName = (persona = {}) => `${persona?.nombre ?? ''} ${persona?.apellido ?? ''}`;

const ESTADO_EJECUCION_VALUES = [
    { id: 'NUEVO', name: 'Nuevo' },
    { id: 'EN_CURSO', name: 'En curso' },
    { id: 'EN_ESPERA', name: 'En espera' },
    { id: 'EJECUTADO', name: 'Ejecutado' },
    { id: 'CONFIRMADO', name: 'Confirmado' },
];

export { fullName, ESTADO_EJECUCION_VALUES };