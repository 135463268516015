import React from 'react';
import {
    List,
    SimpleList,
    Filter,
    SearchInput
} from 'react-admin';

const CategoriaMovimientoFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const CategoriaMovimientoList = props => (
    <List {...props} 
        bulkActionButtons={false}
        filters={<CategoriaMovimientoFilter />}
        >
        <SimpleList
            primaryText={record => record.nombre}
            secondaryText={record => `#${record.id}`}
            tertiaryText={record => record.code}
            linkType="edit"
        />
    </List>
);

export default CategoriaMovimientoList;