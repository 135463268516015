import {
    required,
    minLength,
    maxLength,
} from 'react-admin';

const cuentaSimple = (cuentaPersonal:{ tipo?: string,identificador?: string } = { }) => `${cuentaPersonal?.tipo ?? ''} ${cuentaPersonal?.identificador ?? ''}`;

const validateTipo = [required(), minLength(2), maxLength(20)];

export {
    cuentaSimple,
    validateTipo,
};