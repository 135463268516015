import * as React from "react";


import { CloneButton } from 'react-admin';

const PartialCloneButton = ({mapResource, record, ...props}) => {

    return (
    <CloneButton
        record={{...record, ...mapResource}}
        {...props} 
    />
)};

export default PartialCloneButton;