import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    SelectInput,
    DateInput,
} from 'react-admin';

const dateLastContactGreaterThanToday = 'La fecha de nacimiento no puede ser posterior a la actual';

const validateContactForm = (value) => {
    const dateNow = new Date();
    const dateBirthday = new Date(value);
    if (dateBirthday > dateNow){
        return dateLastContactGreaterThanToday;
    }
    return null;
}

export const PersonaCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="cuil" />
            <ReferenceInput source="generoId" reference="generos">
                <SelectInput optionText="nombre" validate={required()} />
            </ReferenceInput>
            <TextInput source="nombre" validate={required()}/>
            <TextInput source="apellido" validate={required()}/>
            <DateInput source="fechaNacimiento" validate={validateContactForm}/>
            <TextInput source="telefono" />
            <TextInput source="email" validate={required('El campo de email es obligatorio')}/>
            <DateInput source="fechaInicioPladema" validate={validateContactForm}/>
        </SimpleForm>
    </Create>
);