import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { PARTNER, FINANCE, ADMINISTRATIVE, HR } from '../roles';

import FolderSharedIcon from '@material-ui/icons/FolderShared';

import { AsignacionescontratadasCreate } from './AsignacionescontratadasCreate';
import { AsignacionescontratadasShow } from './AsignacionescontratadasShow';
import { AsignacionescontratadasEdit } from './AsignacionescontratadasEdit';
import AsignacionescontratadasList from './AsignacionescontratadasList';

const asignacionescontratadas = (permisos: SGXPermissions) => ({
    icon: FolderSharedIcon,
    create: AsignacionescontratadasCreate,
    edit: AsignacionescontratadasEdit,
    list: permisos.hasAnyAssignment(PARTNER, FINANCE, ADMINISTRATIVE, HR,) ? AsignacionescontratadasList : undefined,
    show: AsignacionescontratadasShow,
    options: {
        submenu: 'staff'
    }
});

export default asignacionescontratadas;