import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { YEAR_RANGE } from '../../../layout/dashboard/index';

import ContratacionesCard from '../ContratacionesCard';
import DateFilterComponent from '../../../libs/sgx/components/DateFilterComponent';

const AsignacionesPage = () => {
    const [selectedYear, setSelectedYear] = useState(YEAR_RANGE[0]);
    const handleDateRangeSelect = (year) => {
        setSelectedYear(year);
    };
    return (
    <Card>
        <Title title="Asignaciones" />
        <CardContent>
            <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
            <ContratacionesCard year={selectedYear} />
        </CardContent>
    </Card>
)};

export default AsignacionesPage;