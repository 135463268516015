import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
    
    MenuProps,
    ReduxState,
    getResources,
    ResourceDefinition,
    DashboardMenuItem,
} from 'react-admin';

import SubMenu from './SubMenu';
import { AppState } from '../types';
import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';
import GroupIcon from '@material-ui/icons/Group';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PageviewIcon from '@material-ui/icons/Pageview';

type MenuName = 'business' | 'staff' | 'work' | 'banking' | 'pivot'| 'more';

const submenu = (submenu: string) => (resource: ResourceDefinition): boolean => 
    (!!resource.hasList && (resource.options?.submenu || '') === submenu);

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        business: true,
        banking: false,
        pivot: false,
        staff: false,
        more: false,
        work: false,
    });

    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change
    const classes = useStyles();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    let resources = useSelector(getResources) || [];

    return (
        <div className={classnames(classes.root, {
                [classes.open]: open,
                [classes.closed]: !open,
            })}
        >
            {' '}
            <DashboardMenuItem />
            <SubMenu
                handleToggle={() => handleToggle('business')}
                isOpen={state.business}
                name="app.menu.business"
                icon={<BusinessIcon />}
                dense={dense}
                resources={resources.filter(submenu('business'))}
            />
            <SubMenu
                handleToggle={() => handleToggle('staff')}
                isOpen={state.staff}
                name="app.menu.staff"
                icon={<GroupIcon />}
                dense={dense}
                resources={resources.filter(submenu('staff'))}
            />
            <SubMenu
                handleToggle={() => handleToggle('work')}
                isOpen={state.work}
                name="app.menu.work"
                icon={<WorkIcon />}
                dense={dense}
                resources={resources.filter(submenu('work'))}
            />
            <SubMenu
                handleToggle={() => handleToggle('banking')}
                isOpen={state.banking}
                name="app.menu.banking"
                icon={<AccountBalanceIcon />}
                dense={dense}
                resources={resources.filter(submenu('banking'))}
            />
            <SubMenu
                handleToggle={() => handleToggle('pivot')}
                isOpen={state.pivot}
                name="app.menu.pivot"
                icon={<PageviewIcon />}
                dense={dense}
                resources={resources.filter(submenu('pivot'))}
            />
            <SubMenu
                handleToggle={() => handleToggle('more')}
                isOpen={state.more}
                name="app.menu.more"
                icon={<MenuOpenIcon />}
                dense={dense}
                resources={resources.filter(submenu(''))}
            />
        
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: 250,
    },
    closed: {
        width: 55,
    },
}));

export default Menu;