import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField, 
    Filter,
    SearchInput,
    ReferenceInput,
} from 'react-admin';

import PeopleReferenceField from '../personas/PeopleReferenceField';
import PersonaInput from '../personas/PersonaAutocompleteInput';

const PersonFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput source="personaId" reference="personas" >
            <PersonaInput />
        </ReferenceInput>
    </Filter>
);

const UserList = props => (
    <List {...props} filters={<PersonFilter />} 
                    bulkActionButtons={false}
                    >
        <Datagrid rowClick="edit">
            <TextField source="username" />
            <PeopleReferenceField source="personaId" sortable={false}/>
            <BooleanField source="enable" />
            <DateField source="lastLogin" showTime locales="es-AR"/>
        </Datagrid>
    </List>
);

export default UserList;