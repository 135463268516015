import React from 'react';
import {
    DateField,
    NumberField,
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
} from 'react-admin';

import { DATE_OPTIONS } from '../../libs/sgx/shared/date';
import { divisaOptions, Divisa } from '../../libs/sgx/shared/currency';

const IngresosShow = props => (
    <Show {...props} >
        <SimpleShowLayout>
            <ReferenceField source="proyectoId" reference="proyectos" link="show">
                <TextField source="nombre" />
            </ReferenceField>
            <ReferenceField source="proyectoAsignacionPresupuestaria" reference="proyectos" link="show">
                <TextField source="nombre" />
            </ReferenceField>
            <TextField source="nombre" />
            
            <NumberField source="montoPesos" locales="es-AR" options={divisaOptions(Divisa.PESO)}/>
            <NumberField source="montoDolares" locales="es-AR" options={divisaOptions(Divisa.DOLAR)}/>

            <DateField source="fechaContrato" locales="es-AR" options={DATE_OPTIONS}/> 
            <DateField source="fechaEmision" locales="es-AR" options={DATE_OPTIONS}/> 
            <DateField source="fechaCobro" locales="es-AR" options={DATE_OPTIONS}/> 
        </SimpleShowLayout>
    </Show>
);

export default IngresosShow;