import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';

const CustomerEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <TextInput source="billingDetails" multiline validate={[required()]}/>
        </SimpleForm>
    </Edit>
);

export default CustomerEdit;
