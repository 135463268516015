import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    required,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

import { DIVISAS_VALUES } from './utils';

const CuentaEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nombre" validate={[required()]}/>
            <RadioButtonGroupInput source="divisa" choices={DIVISAS_VALUES} />
            <ReferenceInput source="legalEntityId"
                    reference="legal-entities"
                    sort={{ field: 'name', order: 'ASC' }}
                    validate={[required()]} >
                <SelectInput optionText="name" optionValue="id"/>
            </ReferenceInput>
            <DateInput source="inicio" validate={[required()]}/>
            <DateInput source="fin" validate={[required()]}/>

            <TextInput source="comentarios" multiline />
        </SimpleForm>
    </Edit>
);

export default CuentaEdit;
