import React from 'react';

import {
    Edit,
    ReferenceInput,
    DateInput,
    SimpleForm,
    TextInput,
    NumberInput,
    required,
} from 'react-admin';

import EditFormButtons from '../../libs/sgx/components/EditFormButtons';

import PersonaInput from '../personas/PersonaAutocompleteInput';

const redirect = ({record}) => `/personas/${record.personaId}/show/retribuciones`;

const RetribucionesEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<EditFormButtons redirectOnSuccess={redirect} />}>
            <ReferenceInput source="personaId" reference="personas" perPage={100} validate={[required()]}>
                <PersonaInput />
            </ReferenceInput>
            <TextInput source="detalle" validate={[required()]}/>
            <NumberInput source="montoPesos"/>
            <NumberInput source="montoDolares"/>
            <DateInput source="fechaInicio" validate={[required()]}/>
            <DateInput source="fechaFin" validate={[required()]}/>
        </SimpleForm>
    </Edit>
);

export default RetribucionesEdit;