import React from 'react';
import { Resource } from 'react-admin';
import SGXPermissions from '../libs/sgx/auth/SGXPermissions';


import businessUnits from './business-units';
import legalEntities from './legal-entities';
//businessUnits
import personas from './personas';
import asignacionescontratadas from './asignacionescontratadas';
import retribuciones from './retribuciones';
import pagosEjecutados from './pagos-ejecutados';
import cuentasPersonales from './cuentas-personales';
import asignaciones from './asignaciones';
import jobs from './jobs';
// work
import proyectos from './proyectos';
import ingresos from './ingresos';
import customers from './customers';
// banking
import cuentas from './cuentas';
import movimientos from './movimientos';
import categoriaMovimiento from './categoria-movimiento';
// pivot
import asignacionPivot from './asignacion-pivot';
import asignacionContratadaPivot from './asignacion-contratada-pivot';
import pagoPivot from './pago-pivot';
import retribucionPivot from './retribucion-pivot';
import retribucionProyectoPivot from './retribucion-proyecto-pivot';

import users from './users';
import passwordReset from './password-reset';
import dolar from './dolar';


export enum MenuGroup {
    EMPLOYEE,
    ACCOUNT,
    PROJECT,
    SALES,
    CONFIG,
};

const resources = (permissions: SGXPermissions) => [
    <Resource name="business-units" {...businessUnits(permissions)} />,
    <Resource name="legal-entities" {...legalEntities(permissions)} />,

    <Resource name="personas" {...personas(permissions)} />,
    <Resource name="asignacionescontratadas" {...asignacionescontratadas(permissions)} />,
    <Resource name="retribuciones" {...retribuciones(permissions)} />,
    <Resource name="pagos-ejecutados" {...pagosEjecutados(permissions)} />,
    <Resource name="cuentas-personales" {...cuentasPersonales(permissions)} />,
    <Resource name="asignaciones" {...asignaciones(permissions)} />,
    <Resource name="jobs" {...jobs(permissions)} />,

    <Resource name="proyectos" {...proyectos(permissions)} />,
    <Resource name="ingresos" {...ingresos(permissions)} />,
    <Resource name="customers" {...customers(permissions)} />,

    <Resource name="cuentas" {...cuentas(permissions)} />,
    <Resource name="movimientos" {...movimientos(permissions)} />,
    <Resource name="resumenes" />,
    <Resource name="categoria-movimiento" {...categoriaMovimiento(permissions)} />,

    <Resource name="asignacion-pivot" {...asignacionPivot(permissions)} />,
    <Resource name="asignacion-contratada-pivot" {...asignacionContratadaPivot(permissions)} />,
    <Resource name="pago-pivot" {...pagoPivot(permissions)} />,
    <Resource name="retribucion-pivot" {...retribucionPivot(permissions)} />,
    <Resource name="retribucion-proyecto-pivot" {...retribucionProyectoPivot(permissions)} />,
    
    
    <Resource name="users" {...users(permissions)} />,
    <Resource name="dolar" {...dolar(permissions)} />,
    
    <Resource name="provincias" />,
    <Resource name="departamentos" />,
    <Resource name="localidades" />,
    <Resource name="password-reset" {...passwordReset} />,
    <Resource name="roles" />,
    <Resource name="generos" />,
    <Resource name="tipos-identificaciones" />,
];

export default resources;