import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
    Datagrid,
    Filter,
    List,
    TextField,
    TextInput,
    SimpleList,
    DateField,
    EditButton,
    SearchInput,
} from 'react-admin';
 	
import { dateFormatter, dateFormatterParams } from '../../libs/sgx/shared/date';

const PersonaFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput source="cuil" />
    </Filter>
);

export const PersonaList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={<PersonaFilter />} >
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record => `${record.nombre}  ${record.apellido}`}
                    secondaryText={record => record.cuil}
                    tertiaryText={record => dateFormatter(record.fechaNacimiento)}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="cuil" />
                    <TextField source="nombre" />
                    <TextField source="apellido" />
                    <DateField source="fechaNacimiento" {...dateFormatterParams} />
                    <TextField source="email" />
                    <TextField source="telefono" />
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
}
