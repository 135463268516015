import React from 'react';
import Button from '@material-ui/core/Button';

import {
    useTranslate,
} from 'react-admin';

import { currencyFormatter, Divisa } from '../../libs/sgx/shared/currency';
import ChartTableField from '../../libs/sgx/components/ChartTableField';

const ProyectosTable = ({id, cerrado}) => {
    const translate = useTranslate();

    const monedaFormatter = currencyFormatter(Divisa.DOLAR);
    const dolarValueFormatter = ({ value }) => monedaFormatter(value);
    const title = cerrado ? 'Proyectos cerrados': 'Proyectos abiertos';

    return (
    <ChartTableField
        title={title}
        measures={[
            `ResumenProyectos.balance_dolares`,
            `ResumenProyectos.futuro_dolares`,
            `ResumenProyectos.ingreso_dolares`,
            `ResumenProyectos.sueldo_dolares`,
            `ResumenProyectos.gastos_dolares`,
        ]}
        dimensions={[
            "ResumenProyectos.id",
            "ResumenProyectos.nombre",
            "ResumenProyectos.entidad_nombre",
            "ResumenProyectos.fecha_inicio",
            "ResumenProyectos.fecha_fin",
        ]}
        filters={[
            {
                "member": "ResumenProyectos.business_unit_id",
                "operator": "equals",
                "values": [
                    `${id}`
                ]
            },
            {
                "member": "ResumenProyectos.cerrado",
                "operator": "equals",
                "values": [
                    `${cerrado}`
                ]
            }
        ]}
        columns={[
            {
                "field": "ResumenProyectos.nombre",
                "headerName": "Proyecto",
                "width": 180,
                renderCell: ({ row }) => {
                    const url = `#/proyectos/${row['ResumenProyectos.id']}/show`;
                    return (
                        <Button href={url} >
                            {row['ResumenProyectos.nombre']}
                        </Button>
                    )
                },
            },
            {
                "field": `ResumenProyectos.balance_dolares`,
                "headerName": translate('cubes.ResumenProyectos.balance_dolares'),
                "width": 180,
                description: 'Ingresos menos asignaciones',
                valueFormatter: dolarValueFormatter,
                align: 'right',
            },
            {
                "field": `ResumenProyectos.futuro_dolares`,
                "headerName": translate('cubes.ResumenProyectos.futuro_dolares'),
                "width": 180,
                description: 'Ingresos pendientes por el proyecto',
                valueFormatter: dolarValueFormatter,
                align: 'right',
            },
            {
                "field": "ResumenProyectos.entidad_nombre",
                "headerName": translate('cubes.ResumenProyectos.entidad_nombre'),
                "width": 150,
            },
            {
                "field": `ResumenProyectos.sueldo_dolares`,
                "headerName": translate('cubes.ResumenProyectos.sueldo_dolares'),
                "width": 180,
                description: 'Inversión en sueldos para el proyecto',
                valueFormatter: dolarValueFormatter,
                align: 'right',
            },
            {
                "field": `ResumenProyectos.ingreso_dolares`,
                "headerName": translate('cubes.ResumenProyectos.ingreso_dolares'),
                "width": 180,
                description: 'Ingresos registrados por el proyecto',
                valueFormatter: dolarValueFormatter,
                align: 'right',
            },
            {
                "field": `ResumenProyectos.gastos_dolares`,
                "headerName": translate('cubes.ResumenProyectos.gastos_dolares'),
                "width": 180,
                description: 'Ingreso registrado en cuentas bancarias',
                valueFormatter: dolarValueFormatter,
                align: 'right',
            },
        ]}
    />
)};

const BusinessUnitProyectos = ({ ...props }) => {

    return (
        <div>
            <ProyectosTable {...props} cerrado={false}/>
            <ProyectosTable {...props} cerrado={true}/>
        </div>
    )
};

export default BusinessUnitProyectos;
