import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { FINANCE, PARTNER } from '../roles';

import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import CategoriaMovimientoCreate from './CategoriaMovimientoCreate';
import CategoriaMovimientoList from './CategoriaMovimientoList';
import CategoriaMovimientoEdit from './CategoriaMovimientoEdit';

export const categoriaMovimientosPermissions = [
    FINANCE,
    PARTNER,
];

const categoriaMovimiento = (permisos: SGXPermissions) => ({
    icon: FormatListNumberedIcon,
    create: CategoriaMovimientoCreate,
    edit: CategoriaMovimientoEdit,
    list: permisos.hasAnyAssignment(...categoriaMovimientosPermissions) ? CategoriaMovimientoList : undefined,
    options: {
        submenu: 'banking'
    }
});

export default categoriaMovimiento;
