import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    required,
} from 'react-admin';

const CategoriaMovimientoCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="nombre" validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export default CategoriaMovimientoCreate;
