import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SmallLineChart from '../charts/SmallLineChart';
import DateFilterComponent from '../../../libs/sgx/components/DateFilterComponent';
import { YEAR_RANGE } from '../index';

const AsignacionesCard = () => {
  const [selectedYear, setSelectedYear] = useState<string>(YEAR_RANGE[0]);

  const dateFilters = [
    {
      "member": "Contrataciones.fecha",
      "operator": "inDateRange",
      "values": [`${selectedYear}-01-01`,`${selectedYear}-12-31`],
    },
  ];

  const timeDimensions = [
    {
      "dimension": "Contrataciones.fecha",
      "granularity": "month",
    }
  ];

  const handleDateRangeSelect = (year: string) => {
    setSelectedYear(year);
  };

  return (
    <Card >
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Horas sin asignar por mes
        </Typography>
        <div style={{ height: '120px', width: '100%' }}>
          <SmallLineChart
            measures={[
              "Contrataciones.horas_sin_asignar",
            ]}
            timeDimensions={timeDimensions}
            filters={dateFilters} />
        </div>
        <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
      </CardContent>
      <CardActions>
        <Button size="small" href="#/dashboard/asignaciones">Mas detalles</Button>
      </CardActions>
    </Card>
  );
};

export default AsignacionesCard;
