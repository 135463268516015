import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    DateField,
    NumberField,
} from 'react-admin';

import CuentaField from '../cuentas/CuentaField';

const MovimientosShow = props => (
    <Show {...props} >
        <SimpleShowLayout>
            <ReferenceField source="cuentaId" reference="cuentas" link={false} >
                <CuentaField />
            </ReferenceField>

            <DateField source="fecha" locales="es-AR" options={{ timeZone: 'UTC' }} />
            <TextField source="detalle" />
            <NumberField source="monto" locales="es-AR" options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}/>
            <ReferenceField source="categoriaId" reference="categoria-movimiento" link={false}>
                <TextField source="nombre" />
            </ReferenceField>
            <ReferenceField source="proyectoId" reference="proyectos" link={false}>
                <TextField source="nombre" />
            </ReferenceField>

            <TextField source="comentarios" />
            
        </SimpleShowLayout>
    </Show>
);

export default MovimientosShow;