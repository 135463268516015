import React from 'react';

import {
    AutocompleteInput,
    CloneButton,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField,
} from 'react-admin';

import { fullName } from '../cuentas/utils';
import CuentaField from '../cuentas/CuentaField';

const movimientoRowStyle = (record, index) => ({
    backgroundColor: record?.monto >= 0 ? '#efe' : '',
});

const MovimientosFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="cuentaId" reference="cuentas" sort={{ field: 'fin', order: 'DESC' }} alwaysOn>
            <AutocompleteInput optionText={fullName} />
        </ReferenceInput>
        <SearchInput source="q" alwaysOn/>
        <ReferenceInput source="proyectoId" reference="proyectos" sort={{ field: 'nombre', order: 'ASC' }}>
            <AutocompleteInput optionText="nombre" />
        </ReferenceInput>
        <ReferenceInput source="categoriaId" reference="categoria-movimiento" sort={{ field: 'nombre', order: 'ASC' }} >
            <AutocompleteInput optionText="nombre" />
        </ReferenceInput>
    </Filter>
);

const MovimientosList = props => (
    <List {...props} 
        filters={<MovimientosFilter />} 
        sort={{ field: 'fecha', order: 'DESC' }}>
        <Datagrid rowClick="edit" rowStyle={movimientoRowStyle}>
            <ReferenceField source="cuentaId" reference="cuentas" link="show">
                <CuentaField />
            </ReferenceField>
            <DateField source="fecha" locales="es-AR" options={{ timeZone: 'UTC' }}/>
            <TextField source="detalle" />
            <NumberField source="monto" locales="es-AR" options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}/>
            <ReferenceField source="categoriaId" reference="categoria-movimiento">
                <TextField source="nombre" />
            </ReferenceField>
            <ReferenceField source="proyectoId" reference="proyectos">
                <TextField source="nombre" />
            </ReferenceField>
            <CloneButton />
        </Datagrid>
    </List>
);

export default MovimientosList;