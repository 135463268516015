import React from 'react';
import {
    Datagrid,
    TextField,
    List
} from 'react-admin';

const JobList = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="name" />
        </Datagrid>
    </List>
);

export default JobList;