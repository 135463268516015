import React from 'react';
import {
    AutocompleteInput,
    DateInput,
    Edit,
    NumberInput,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput,
    useNotify,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const MovimientosRowEdit = props => {
    const notify = useNotify();
    const classes = useStyles();
    const onSuccess = () => {
        notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }});
    };
    return <Edit {...props} title=" " onSuccess={onSuccess} mutationMode="pessimistic">
        <SimpleForm>
            <DateInput source="fecha" validate={[required()]} formClassName={classes.inlineBlock}/>
            <NumberInput source="monto" locales="es-AR" validate={[required()]} formClassName={classes.inlineBlock}/>
            <TextInput source="detalle" fullWidth />
            <ReferenceInput source="categoriaId" reference="categoria-movimiento" allowEmpty formClassName={classes.inlineBlock}>
                <AutocompleteInput optionText="nombre" />
            </ReferenceInput>
            <ReferenceInput source="proyectoId" reference="proyectos" allowEmpty formClassName={classes.inlineBlock}>
                <AutocompleteInput optionText="nombre" />
            </ReferenceInput>
            <TextInput source="comentarios" multiline fullWidth />
        </SimpleForm>
    </Edit>
};

export default MovimientosRowEdit;