import { PermissionsDto, RoleAssignment } from '../api/model';

class SGXPermissions {
    roleAssignments: RoleAssignment[];
    
    constructor(permissions: PermissionsDto) {
        this.roleAssignments = permissions.roleAssignments;
    }


    hasAnyAssignment(...anyAssignments: RoleAssignment[]) {
        if (anyAssignments.length === 0) {
            return true;
        }
        let hasAny = anyAssignments.find(assignment => 
            this.roleAssignments.find(userAssignment => userAssignment.role === assignment.role && userAssignment.businessId === assignment.businessId)
        ) !== undefined;
        // console.log(`hasAnyAssignment ${hasAny}`, {anyAssignments, roleAssignments: this.roleAssignments});
        return hasAny;
    }

}
  
export default SGXPermissions;
