import React from 'react';
import {
    List,
    Filter,
    ReferenceInput,
    DateInput,
    DateField,
    Datagrid,
    TextField,
    ReferenceField,
    AutocompleteInput,
} from 'react-admin';

import { DATE_OPTIONS } from '../../libs/sgx/shared/date';

import PersonaField from '../personas/PersonaField';
import MontoField from '../cuentas/MontoField';
import { DIVISA_CURRENCY } from '../cuentas/utils';
import { fullName } from '../personas/utils';

const fechaFin = new Date().toISOString().substring(0,10);

const RetribucionProyectoPivotFilter = (props) => (
    <Filter {...props}>
        <DateInput source="fecha" />
        <ReferenceInput source="personaId" reference="personas">
            <AutocompleteInput optionText={fullName} />
        </ReferenceInput>
        <ReferenceInput source="proyectoId" reference="proyectos" >
            <AutocompleteInput optionText="nombre" />
        </ReferenceInput>
    </Filter>
);

const RetribucionProyectoPivotList = props => (
    <List {...props}
        filters={<RetribucionProyectoPivotFilter />}
        filterDefaultValues={{ fechaFin }} 
        bulkActionButtons={false}
    >
        <Datagrid rowClick={null} >
            <DateField source="fecha" locales="es-AR" options={DATE_OPTIONS}/> 
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <ReferenceField source="proyectoId" reference="proyectos" link="show">
                <TextField source="nombre" />
            </ReferenceField>
            <ReferenceField source="jobId" reference="jobs" link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="horasMensuales" />
            <MontoField source="montoPesos" divisa={DIVISA_CURRENCY.PESO}/>
            <MontoField source="montoDolares" divisa={DIVISA_CURRENCY.DOLAR}/>
        </Datagrid>
    </List>
);

export default RetribucionProyectoPivotList;