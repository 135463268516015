import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import {
    LIST,
} from './permissions';

import CardTravelIcon from '@material-ui/icons/CardTravel';

import RetribucionesCreate from './RetribucionesCreate';
import RetribucionesList from './RetribucionesList';
import RetribucionesShow from './RetribucionesShow';
import RetribucionesEdit from './RetribucionesEdit';

const retribuciones = (permisos: SGXPermissions) => ({
    icon: CardTravelIcon,
    create: RetribucionesCreate,
    list: permisos.hasAnyAssignment(...LIST) ? RetribucionesList : undefined,
    show: RetribucionesShow,
    edit: RetribucionesEdit,
    options: {
        submenu: 'staff'
    }
});

export default retribuciones;
