import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ChartTableField from '../../libs/sgx/components/ChartTableField';
import { currencyFormatter, Divisa } from '../../libs/sgx/shared/currency';
import { formatDistance } from '../../libs/sgx/shared/date';


const monedaFormatter = currencyFormatter(Divisa.DOLAR);
const dolarValueFormatter = ({ value }) => monedaFormatter(value);
const fechaValueFormatter = ({ value }) => formatDistance(value);

const IngresosPendientesCard = ({
    paraCobrar,
    title,
}) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography gutterBottom variant="h6" display="block">
                    {title}
                </Typography>
                <ChartTableField
                    measures={[
                        `IngresosFuturos.monto`,
                    ]}
                    dimensions={[
                        "IngresosFuturos.proyecto_nombre",
                        "IngresosFuturos.nombre",
                        "IngresosFuturos.customer_nombre",
                        "IngresosFuturos.fecha_contrato",
                        "IngresosFuturos.fecha_emision",
                    ]}
                    order={{
                        "IngresosFuturos.fecha_contrato": "asc"
                    }}
                    filters={[
                        {
                            "member": "IngresosFuturos.para_cobrar",
                            "operator": "equals",
                            "values": [
                                paraCobrar
                            ]
                        }
                    ]}
                    columns={[
                        {
                            "field": "IngresosFuturos.nombre",
                            "headerName": "Nombre",
                            "width": 430,
                        },
                        {
                            "field": `IngresosFuturos.monto`,
                            "headerName": "Monto",
                            "width": 180,
                            description: 'Monto en US$',
                            valueFormatter: dolarValueFormatter,
                            align: 'right',
                        },
                        {
                            "field": "IngresosFuturos.fecha_contrato",
                            "headerName": "Fecha",
                            valueFormatter: fechaValueFormatter,
                            "width": 180,
                        },
                        {
                            "field": "IngresosFuturos.proyecto_nombre",
                            "headerName": "Proyecto",
                            "width": 180,
                        },
                        {
                            "field": "IngresosFuturos.customer_nombre",
                            "headerName": "Cliente",
                            "width": 180,
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
}

export default IngresosPendientesCard;
