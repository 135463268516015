import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useRecordContext } from 'react-admin';

import ContratacionesCard from '../../layout/dashboard/ContratacionesCard';
import { YEAR_RANGE } from '../../layout/dashboard/index';
import ResumenDeudaPersona from '../../layout/dashboard/charts/ResumenDeudaPersona';
import DateFilterComponent from '../../libs/sgx/components/DateFilterComponent';

const PersonaMovimientos = (props) => {
    const [selectedYear, setSelectedYear] = useState(YEAR_RANGE[0]);
    const { source } = props;
    const record = useRecordContext(props);
    const personaId = record[source];

    const handleDateRangeSelect = (year) => {
        setSelectedYear(year);
    };

    return <div>
        <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
        <ContratacionesCard personaId={personaId} year={selectedYear}/>
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h1>Total</h1>
                    <div style={{ height: '70vh' }}>
                        <ResumenDeudaPersona personaId={personaId} year={selectedYear}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    </div>;
}

PersonaMovimientos.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default PersonaMovimientos;