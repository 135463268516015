import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ChartTableField from '../../libs/sgx/components/ChartTableField';
import { currencyFormatter, Divisa } from '../../libs/sgx/shared/currency';

const monedaFormatter = currencyFormatter(Divisa.DOLAR);
const dolarValueFormatter = ({ value }) => monedaFormatter(value);

const ResumenProyectosCard = () => {
    
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography gutterBottom variant="h6" display="block">
                    Balance de proyectos abiertos
                </Typography>
                <ChartTableField
                    measures={[
                        `ResumenProyectos.balance_dolares`,
                        `ResumenProyectos.futuro_dolares`,
                    ]}
                    dimensions={[
                        "ResumenProyectos.nombre",
                        "ResumenProyectos.entidad_nombre",
                        "ResumenProyectos.business_unit_name",
                    ]}
                    order={{
                        "ResumenProyectos.futuro_dolares": "desc",
                        "ResumenProyectos.balance_dolares": "asc"
                    }}
                    filters={[
                        {
                            "member": "ResumenProyectos.cerrado",
                            "operator": "equals",
                            "values": [
                                `false`
                            ]
                        }
                    ]}
                    columns={[
                        {
                            "field": "ResumenProyectos.nombre",
                            "headerName": "Proyecto",
                            "width": 180,
                        },
                        {
                            "field": `ResumenProyectos.balance_dolares`,
                            "headerName": "Balance",
                            "width": 180,
                            description: 'Ingresos menos RRHH',
                            valueFormatter: dolarValueFormatter,
                            align: 'right',
                        },
                        {
                            "field": `ResumenProyectos.futuro_dolares`,
                            "headerName": "Futuro Ingreso",
                            "width": 180,
                            description: 'Ingresos pendientes por el proyecto',
                            valueFormatter: dolarValueFormatter,
                            align: 'right',
                        },
                        {
                            "field": "ResumenProyectos.business_unit_name",
                            "headerName": "Unidad de Negocio",
                            "width": 200,
                        },
                        {
                            "field": "ResumenProyectos.entidad_nombre",
                            "headerName": "Entidad",
                            "width": 200,
                        },

                    ]}
                />
            </Grid>
        </Grid>
    );
}

export default ResumenProyectosCard;
