import React from 'react';
import {
    useMutation,
    useTranslate,
    useGetList,
} from 'react-admin';

import {
    Typography,
    Card,
    CardHeader,
    Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { parseISO, isFuture } from 'date-fns';

import OpenPageButton from './OpenPageButton';
import passwordReset from '../password-reset';

import { passwordResetUrl } from '../../layout/routes';
import { openNewTab } from '../../providers/utils/openLink'

const openPasswordReset = (token) => openNewTab(`#${passwordResetUrl(token)}`);

const useEventStyles = makeStyles({
    card: {
        margin: '0 0 1em 1em',
    },
    cardHeader: {
        alignItems: 'flex-start',
    },
    clamp: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
});

const isNotExpired = ({expiryDate}) => isFuture(parseISO(expiryDate));


const ResetPasswordCard = ({ actionClick, loading }) => {
    const translate = useTranslate();
    const classes = useEventStyles();
    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Avatar
                        className={classes.avatar}
                    >
                        <passwordReset.icon />
                    </Avatar>
                }
                action={<OpenPageButton actionClick={actionClick} disabled={loading} actionName="reset"/>}
                title={translate('resources.users.fieldGroups.passwordResets')}
                subheader={
                    <>
                        <Typography variant="body2">Puede definir una nueva clave de acceso</Typography>
                    </>
                }
            />
        </Card>
    );
};

const PasswordResetList = ({ record }) => {
    const { data: resetCodes, ids: resetCodesId } = useGetList(
        'password-reset',
        { page: 1, perPage: 100 },
        { field: 'expiryDate', order: 'DESC' },
        { userId: record && record.id, enable: true }
    );

    const [createPasswordReset, { loading }] = useMutation({
        type: 'create',
        resource: 'password-reset',
        payload: { data: { userId: record && record.id } }
    }, { 
        onSuccess: ({ data }) => {
            openPasswordReset(data.token);
        }
    });

    const validResetCode = (resetCodesId || [])
        .map(resetCodeId => resetCodes[resetCodeId])
        .find(isNotExpired);

    const actionClick = (!validResetCode) ? createPasswordReset : () => openPasswordReset(validResetCode.token);


    return (
        <>
            <ResetPasswordCard actionClick={actionClick} loading={loading}></ResetPasswordCard>
        </>
    );
};

export default PasswordResetList;
