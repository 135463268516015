import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    required,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';
import { InputLabel } from '@material-ui/core';
import { DIVISAS_VALUES } from './utils';
import {defaultPeriodo, Month} from '../../libs/sgx/shared/date';

const InicioFinInput = () => (
    <div>
        <InputLabel >Periodo fiscal</InputLabel>
        <DateInput source="inicio" validate={[required()]} defaultValue={start}/>
        &nbsp;
        <DateInput source="fin" validate={[required()]} defaultValue={end}/>
    </div>
);


const {start, end} = defaultPeriodo(Month.July);

const CuentaCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="nombre" validate={[required()]}/>
            <RadioButtonGroupInput source="divisa" choices={DIVISAS_VALUES} />
            <ReferenceInput source="legalEntityId"
                    reference="legal-entities"
                    sort={{ field: 'name', order: 'ASC' }}
                    validate={[required()]} >
                <SelectInput optionText="name" optionValue="id"/>
            </ReferenceInput>
            <InicioFinInput />
            <TextInput source="comentarios" multiline />
        </SimpleForm>
    </Create>
);

export default CuentaCreate;