import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    required,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
} from 'react-admin';

export const ProyectoCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="nombre" validate={[required()]}/>
            <ReferenceInput source="customerId"
                    reference="customers"
                    sort={{ field: 'name', order: 'ASC' }} >
                <AutocompleteInput optionText="name" optionValue="id"/>
            </ReferenceInput>
            <DateInput source="fechaInicio" />
            <ReferenceInput source="legalEntityId"
                    reference="legal-entities"
                    sort={{ field: 'name', order: 'ASC' }}
                    validate={[required()]} >
                <SelectInput optionText="name" optionValue="id"/>
            </ReferenceInput>
            <ReferenceInput source="businessUnitId"
                    reference="business-units"
                    sort={{ field: 'name', order: 'ASC' }}
                    validate={[required()]} >
                <SelectInput optionText="name" optionValue="id"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);