import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
} from 'react-admin';
import { LIST as MOVIMIENTOS_LIST } from '../movimientos/permissions';
import TypographyField from '../../libs/sgx/components/TypographyField';
import ShowFormTitle from '../../libs/sgx/components/ShowFormTitle';
import BusinessUnitProyectos from './BusinessUnitProyectos';

const BusinessUnitShow = props => {
    const accesoAMovimientos = props.permissions?.hasAnyAssignment(...MOVIMIENTOS_LIST);
    return (
        <Show {...props} title={<ShowFormTitle mapper={legalEntity => legalEntity?.name} />}>
            <TabbedShowLayout>
                <Tab label="Resumen">
                    <TypographyField source="name" variant="h4"></TypographyField>
                    {accesoAMovimientos && <BusinessUnitProyectos {...props}/>}
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

export default BusinessUnitShow;