import React from 'react';
import {
    List,
    SimpleList,
    Filter,
    SelectInput,
    ReferenceInput
} from 'react-admin';

import { dayFormatter } from '../../libs/sgx/shared/date';
import { DIVISA_MAP, DIVISAS_VALUES } from './utils'

import { fullName } from './utils';

const CuentasFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            alwaysOn
            choices={DIVISAS_VALUES}
            source="divisa"
        />
        <ReferenceInput source="legalEntityId"
                reference="legal-entities"
                sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" optionValue="id"/>
        </ReferenceInput>
    </Filter>
);

const CuentasList = props => (
    <List {...props}
        filters={<CuentasFilter />}
        sort={{ field: 'inicio', order: 'DESC' }}
    >
        <SimpleList
            primaryText={record => `${fullName(record)}`}
            secondaryText={record => DIVISA_MAP[record.divisa]}
            tertiaryText={record => `${dayFormatter(record.inicio)} al ${dayFormatter(record.fin)}`}
            linkType="show"
        />
    </List>
);

export default CuentasList;