import { AuthProvider, HttpError } from 'react-admin';
import SGXPermissions from '../auth/SGXPermissions';

import { 
    sgxFetchApi, 
    sgxFetchApiWithToken, 
    jsonPayload,
} from './fetch';
import { UserIdentityDto, PermissionsDto } from './model';
import { clearTokens, retrieveToken, saveTokens } from './tokenStorage';

const isPublicRouter = (path = '') => path.startsWith('#/auth/');
const guestPermissions = (): Promise<SGXPermissions> => Promise.resolve(new SGXPermissions({roleAssignments: []}));
const fetchPermissions = (): Promise<SGXPermissions> => 
    sgxFetchApiWithToken<PermissionsDto>('account/permissions')
        .then(permissions => new SGXPermissions(permissions));

const authProvider: AuthProvider = {
    // authentication
    login: ({ username, password }) => {
        const options = jsonPayload('POST', { username, password });
        return sgxFetchApi<{ token: string, refreshToken: string }>('auth', options)
            .then(({ token, refreshToken }) => {
                saveTokens(token, refreshToken);
            });
    },
    checkError: (error: HttpError) => {
        const status = error.status;
        if (status === 401 ) {
            clearTokens();
            return Promise.reject();
        }
        console.log('authProvider checkError', error);
        return Promise.resolve()
    },
    checkAuth: () => retrieveToken() ? Promise.resolve() : Promise.reject(),
    logout: () => {
        clearTokens();
        return Promise.resolve();
    },
    getIdentity: 
        () => sgxFetchApiWithToken<UserIdentityDto>('account/info')
            .then(({id, name}) => ({id, fullName: name})),
    // authorization
    getPermissions: 
        _ => isPublicRouter(window.location.hash) ? guestPermissions() : fetchPermissions(),
};

export default authProvider;
