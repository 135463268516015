import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import {
    LIST,
} from './permissions';

import ReceiptIcon from '@material-ui/icons/Receipt';

import { IngresosCreate } from './IngresosCreate';
import { IngresosEdit } from './IngresosEdit';
import IngresosList from './IngresosList';
import IngresosShow from './IngresosShow';

const ingresos = (permisos: SGXPermissions) => ({
    icon: ReceiptIcon,
    create: IngresosCreate,
    edit: IngresosEdit,
    list: permisos.hasAnyAssignment(...LIST) ? IngresosList : undefined,
    show: IngresosShow,
    options: {
        submenu: 'work'
    }
});

export default ingresos;