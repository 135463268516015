import React from 'react';
import {
    Create,
    SimpleForm,
    DateInput,
    required,
    NumberInput,
} from 'react-admin';

const DolarCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <DateInput source="id" validate={[required()]}/>
            <NumberInput source="oficial" locales="es-AR" validate={[required()]}/>
            <NumberInput source="alternativo" locales="es-AR" validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export default DolarCreate;