import React from 'react';
import {
    ReferenceInput,
    DateInput,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    required,
    RadioButtonGroupInput,
    useNotify, useRefresh, useRedirect,
} from 'react-admin';
import { useFormStyles } from '../../libs/sgx/components/layout';
import { DIVISAS_VALUES } from '../cuentas/utils';
import { ESTADO_EJECUCION_VALUES } from '../personas/utils';
import PersonaInput from '../personas/PersonaAutocompleteInput';

const postDefaultValue = () => ({ divisa: 'PESO', estado: 'NUEVO', fechaEsperada: new Date() });

const PagoEjecutadoCreate = props => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const classes = useFormStyles();

    const onSuccess = ({data}) => {
        notify('ra.notification.created');
        redirect(`/personas/${data.personaId}/show/pagos-ejecutados`);
        refresh();
    };
    return (
    <Create {...props} onSuccess={onSuccess}>
        <SimpleForm initialValues={postDefaultValue}>
            <ReferenceInput source="personaId" reference="personas" validate={[required()]}>
                <PersonaInput />
            </ReferenceInput>
            <RadioButtonGroupInput source="divisa" choices={DIVISAS_VALUES} />
            <DateInput source="fechaEsperada" validate={[required()]}/>
            <NumberInput source="monto" locales="es-AR" validate={[required()]} formClassName={classes.inlineBlock} />
            <NumberInput source="neto" locales="es-AR" formClassName={classes.inlineBlock} helperText="resources.pagos-ejecutados.fieldsHelper.neto"/>
            <ReferenceInput source="personaEncargadaId" reference="personas" allowEmpty>
                <PersonaInput />
            </ReferenceInput>
            <TextInput source="detalle" />
            <ReferenceInput source="personaProxyId" reference="personas" allowEmpty>
                <PersonaInput />
            </ReferenceInput>
            <DateInput source="fechaEjecucion" />
            <RadioButtonGroupInput source="estado" choices={ESTADO_EJECUCION_VALUES} />
        </SimpleForm>
    </Create>
)};

export default PagoEjecutadoCreate;
