import React from 'react';
import {
    List,
    SimpleList,
    Filter,
    SearchInput,
} from 'react-admin';

const CustomerFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const CustomerList = props => (
    <List {...props} 
        filters={<CustomerFilter />}
    >
        <SimpleList
            primaryText={record => `🤝 ${record.name}`}
            linkType="show"
        />
    </List>
);

export default CustomerList;