import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import PasswordResetList from './PasswordResetList';

const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

const UserAside = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            {record && <PasswordResetList record={record} basePath={basePath} />}
        </div>
    );
};

UserAside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
};


export default UserAside;