import React from 'react';
import {
    List,
    SimpleList,
} from 'react-admin';

const BusinessUnitList = props => (
    <List {...props}>
        <SimpleList
            primaryText={record => `🏢 ${record.name}`}
            linkType="show"
        />
    </List>
);

export default BusinessUnitList;