import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Toolbar,
    SaveButton,
    DeleteButton,
} from 'react-admin';

const DEFAULT_REDIRECT = () => undefined;

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

/**
 * La manera en que se pueden customizar los botones Guardar/Eliminar es con un Toolbar cutom:
 * https://marmelab.com/react-admin/CreateEdit.html#toolbar
 * 
 * Params:
 *      * redirectOnSuccess: Función que usa props para retornar la URL para la redirección
 * 
 */
const EditFormButtons = ({redirectOnSuccess = DEFAULT_REDIRECT, ...props}) => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton redirect={redirectOnSuccess(props)} />
        <DeleteButton redirect={redirectOnSuccess(props)} />
    </Toolbar>
);

export default EditFormButtons;