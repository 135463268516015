import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';

import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';

import {
    ADMINISTRATIVE,
    FINANCE,
    PARTNER,
} from '../roles';


import CuentaPersonalCreate from './CuentaPersonalCreate';
import CuentaPersonalList from './CuentaPersonalList';
import CuentaPersonalEdit from './CuentaPersonalEdit';

export const cuentasPersonalesPermissions = [
    FINANCE,
    PARTNER,
    ADMINISTRATIVE,
];

const cuentas = (permisos: SGXPermissions) => ({
    icon: MoveToInboxIcon,
    create: CuentaPersonalCreate,
    edit: CuentaPersonalEdit,
    list: permisos.hasAnyAssignment(...cuentasPersonalesPermissions) ? CuentaPersonalList : undefined,
    options: {
        submenu: 'staff'
    }
    
});

export default cuentas;
