import * as React from 'react';
import { Route } from 'react-router-dom';
import PasswordResetPage from './password-reset';

import { AsignacionesPage } from '../dashboard/asignaciones';
import { IngresosPage } from '../dashboard/ingresos';
import { ProyectosPage } from '../dashboard/proyectos';
import { MovimientosPage } from '../dashboard/movimientos';

const passwordResetUrl = (token) => `/auth/password-reset/${token}`;

const customRoutes = [
    <Route exact path={passwordResetUrl(':token')} render={() => <PasswordResetPage />} noLayout />,

    <Route exact path="/dashboard/asignaciones" component={AsignacionesPage} />,
    <Route exact path="/dashboard/ingresos" component={IngresosPage} />,
    <Route exact path="/dashboard/proyectos" component={ProyectosPage} />,
    <Route exact path="/dashboard/saldos" component={MovimientosPage} />,
];

export { customRoutes, passwordResetUrl };
