import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import DoneOutline from '@material-ui/icons/DoneOutline';


const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      backgroundColor: green[800],
    },
  }),
);

const PasswordSetCard = ({email}) => {
    const classes = useStyles();
    return (
      <React.Fragment>
        <Card style={{ margin: 8 }}>
        <CardHeader
            avatar={
              <Avatar aria-label="done" className={classes.avatar}>
                <DoneOutline />
              </Avatar>
            }
            title="Nueva clave"
            titleTypographyProps={{variant:'h5' }}
          />
          <CardContent>
          
            <Typography variant="subtitle1">
              Se definió correctamente la clave para el usuario <code>{email}</code>
            </Typography>
          </CardContent>
        </Card>
        <Typography variant="caption" display="block" gutterBottom align="center">
          Puede cerrar esta ventana
        </Typography>
      </React.Fragment>
    );
  };

export default PasswordSetCard;
