import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    ReferenceManyField,
    Datagrid,
} from 'react-admin';

import PersonaField from '../personas/PersonaField';
import MontoField from '../cuentas/MontoField';
import { DIVISA_CURRENCY } from '../cuentas/utils';

const RetribucionPivotShow = props => (
    <Show {...props} >
        <SimpleShowLayout>
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <TextField source="anio" />
            <TextField source="mes" />
            <h1>Retribución</h1>
            <ReferenceManyField
                addLabel={false}
                reference="retribucion-pivot"
                target="id"
            >
                <Datagrid rowClick="show">
                    <MontoField source="sueldoPesos" divisa={DIVISA_CURRENCY.PESO}/>
                    <MontoField source="sueldoDolares" divisa={DIVISA_CURRENCY.DOLAR}/>
                </Datagrid>
            </ReferenceManyField>
            <h1>Pago</h1>
            <ReferenceManyField
                addLabel={false}
                reference="pago-pivot"
                target="id"
            >
                <Datagrid rowClick="show">
                    <MontoField source="sueldoPesos" divisa={DIVISA_CURRENCY.PESO}/>
                    <MontoField source="sueldoDolares" divisa={DIVISA_CURRENCY.DOLAR}/>
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

export default RetribucionPivotShow;