import {
    Divisa
} from '../../libs/sgx/shared/currency';

import {
    yearShortFormatter,
} from '../../libs/sgx/shared/date';

const DIVISAS_VALUES = [
    { id: Divisa.PESO, name: 'Pesos 🇦🇷' },
    { id: Divisa.DOLAR, name: 'Dólares 🇺🇸' },
];

const DIVISA_EMOJI = {
    DOLAR: '🇺🇸',
    PESO: '🇦🇷',
};

const DIVISA_MAP = {
    DOLAR: `${DIVISA_EMOJI[Divisa.DOLAR]} Dólares`,
    PESO: `${DIVISA_EMOJI[Divisa.PESO]} Pesos`,
};

const DIVISA_CURRENCY = {
    DOLAR: 'USD',
    PESO: 'ARS',
};

interface CuentaBean {
    nombre: string;
    divisa: Divisa;
    inicio: string; //2022-07-01
    fin: string; //2023-06-30
}

const buildFullname = ({nombre, divisa, fin, inicio}: CuentaBean) => 
    `${DIVISA_EMOJI[divisa]} ${yearShortFormatter(inicio)}-${yearShortFormatter(fin)} ${nombre}`;

const fullName = (cuenta: any) => cuenta?.id ? buildFullname(cuenta) : '';

export {
    DIVISAS_VALUES,
    DIVISA_MAP,
    DIVISA_CURRENCY,
    fullName,
};
