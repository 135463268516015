import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import { ADMINISTRATIVE, FINANCE, PARTNER } from '../roles';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import CustomerCreate from './CustomerCreate';
import CustomerList from './CustomerList';
import CustomerShow from './CustomerShow';
import CustomerEdit from './CustomerEdit';

export const customersPermissions = [
    ADMINISTRATIVE,
    FINANCE,
    PARTNER,
];

const gastos = (permisos: SGXPermissions) => ({
    icon: BusinessCenterIcon,
    create: CustomerCreate,
    edit: CustomerEdit,
    list: permisos.hasAnyAssignment(...customersPermissions) ? CustomerList : undefined,
    show: CustomerShow,
    options: {
        submenu: 'work'
    }
});

export default gastos;
