import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    RadioButtonGroupInput,
} from 'react-admin';

import PersonaInput from '../personas/PersonaAutocompleteInput';
import { DIVISAS_VALUES } from '../cuentas/utils';
import { validateTipo } from './utils';

const CuentaPersonalCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="personaId" reference="personas" perPage={100} validate={[required()]}>
                <PersonaInput />
            </ReferenceInput>
            <RadioButtonGroupInput source="divisa" choices={DIVISAS_VALUES} validate={[required()]}/>
            <TextInput source="tipo" validate={validateTipo} helperText="resources.cuentas-personales.fieldsHelper.tipo" />
            <TextInput source="identificador" validate={[required()]} helperText="resources.cuentas-personales.fieldsHelper.identificador"/>
            <TextInput source="detalles" multiline helperText="resources.cuentas-personales.fieldsHelper.detalles"/>
        </SimpleForm>
    </Create>
);

export default CuentaPersonalCreate;