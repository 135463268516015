import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    ReferenceField,
} from 'react-admin';

import PersonaField from '../personas/PersonaField';

const RetribucionesShow = props => (
    <Show {...props} >
        <SimpleShowLayout>
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <TextField source="detalle" />
            <TextField source="montoPesos" />
            <TextField source="montoDolares" />
            <DateField source="fechaInicio" locales="es-AR" options={{ timeZone: 'UTC' }}/>
            <DateField source="fechaFin" locales="es-AR" options={{ timeZone: 'UTC' }}/>
        </SimpleShowLayout>
    </Show>
);

export default RetribucionesShow;