import React from 'react';
import {
    useTranslate,
} from 'react-admin';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import AddCircleIcon from '@material-ui/icons/PlaylistAdd';

const ButtonBar = ({children}) => (
    <Grid container justifyContent="flex-end">
        {children}
    </Grid>
  );


//Taken from examples/simple/../posts/show.js
//This button takes the user to the create form of given resource.
//reference: The resource to be created.
//refFieldName: The field of the child entity that points
//to the father. This field will be prefilled in the create form.
//label: The button's label.
const CreateRelatedButton = ({ record, reference, refFieldName, label}) => {
    const newRecordValues = {[refFieldName]: record.id};
    const translate = useTranslate();
    return (
        <ButtonBar>
            <Button
                component={Link}
                to={{
                    pathname: `/${reference}/create`,
                    state: { record: newRecordValues },
                }}
                startIcon={<AddCircleIcon />}
                color="primary" 
                >{translate(label)}</Button>
        </ButtonBar>
    );
};

export default CreateRelatedButton;
